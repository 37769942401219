import { connect, Field as FormikField } from 'formik'
import { Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { formFieldNames } from '@/entity'
import { message } from 'antd'
import React from 'react'
import RadioStaff from '@/components/RadioStaff/RadioStaff'

export const ListOfEquipment = connect(
	({ formik: { setFieldValue, values }, name, index, showIndex, isFormDirty, genders, currentLanguage }) => {
		const { t } = useTranslation()
		const stepText = 'occupational_risks_assessment.steps.step_4.'

		let preffix = name + '[' + index + '].'

		const removeCurrentItem = () => {
			let items = values[name]
			if (index > -1 && index < items.length) {
				const newItems = [...items]
				newItems.splice(index, 1)
				setFieldValue(name, newItems)
			}
		}

		return (
			<section>
				{/*<h5>{t('accidentsPage.register.victim') + (showIndex ? ' ' + (index + 1) : '')} </h5>*/}
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={16}>
					<Grid item xs={12}>
						<FormikField name={formFieldNames.employeeInitials}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="selectSearch"
									type="text"
									isRequired
									label={t(stepText + 'field_1')}
									placeholder={t(stepText + 'field_1')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={preffix + 'object'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="datePicker"
									options={genders}
									isRequired
									label={t(stepText + 'field_2')}
									placeholder={t(stepText + 'field_2')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} spacing={4} columns={16}>
					<Grid item xs={5}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="selectSearch"
									options={genders}
									isRequired
									label={t(stepText + 'field_3')}
									placeholder={t(stepText + 'field_3')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									value={values[preffix + 'twoRadio']}
									firstId="1"
									secondId="2"
									isRequired
									label={t(stepText + 'field_4')}
									placeholder={t(stepText + 'field_4')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} spacing={4} columns={15}>
					<Grid item xs={5}>
						<FormikField name={preffix + 'object'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									value={values[preffix + 'twoRadio']}
									firstId="1"
									secondId="2"
									isRequired
									label={t(stepText + 'field_5')}
									placeholder={t(stepText + 'field_5')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={5}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									value={values[preffix + 'twoRadio']}
									firstId="1"
									secondId="2"
									isRequired
									label={t(stepText + 'field_6')}
									placeholder={t(stepText + 'field_6')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={5}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									value={values[preffix + 'twoRadio']}
									firstId="1"
									secondId="2"
									isRequired
									label={t(stepText + 'field_7')}
									placeholder={t(stepText + 'field_7')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} columns={8}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_8')}
									placeholder={t(stepText + 'field_8')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				{showIndex && (
					<Button buttonClassName={'float-end'} type="bare" onClick={removeCurrentItem}>
						{t('remove')}
					</Button>
				)}
			</section>
		)
	}
)
