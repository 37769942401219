export const SET_EQUIPMENT_LOADING = 'equipments/SET_EQUIPMENT_LOADING'
export const SET_EQUIPMENTS = 'equipments/SET_OCCUPATIONAL_RISKS'
export const SET_EQUIPMENT_SUCCESS = 'equipments/SET_EQUIPMENT_SUCCESS'
export const SET_CREATE_EQUIPMENT_XML = 'equipments/SET_CREATE_EQUIPMENT_XML'
export const SET_INITIAL_EQUIPMENT = 'equipments/SET_INITIAL_OCCUPATIONAL_RISK'
export const SET_EQUIPMENT_FIELDS = 'equipments/SET_EQUIPMENT_FIELDS'
export const SET_SELECTED_EQUIPMENTS_STATE = 'equipments/SET_SELECTED_OCCUPATIONAL_RISKS_STATE'
export const DELETE_EQUIPMENT_SOME = 'equipments/DELETE_EQUIPMENT_SOME'
export const RESET_EQUIPMENT = 'equipments/RESET_OCCUPATIONAL_RISK'
export const SET_EQUIPMENT_ERROR = 'equipments/SET_EQUIPMENT_ERROR'
export const TOGGLE_LOADING = 'equipments/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'equipments/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'equipments/TOGGLE_ERROR'
export const SET_TERMINATE_XML = 'equipments/SET_TERMINATE_XML'
export const SET_REMOVE_XML = 'equipments/SET_REMOVE_XML'
export const SET_RECOVER_XML = 'equipments/SET_RECOVER_XML'
export const SET_EDIT_XML = 'equipments/SET_EDIT_XML'
export const SET_EDIT_EQUIPMENT = 'equipments/SET_EDIT_EQUIPMENT'
export const GET_DATA_BY_BIN = 'equipments/GET_DATA_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN = 'equipments/GET_EMPLOYER_PERMISSION_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN_START = 'equipments/GET_EMPLOYER_PERMISSION_BY_BIN_START'
export const GET_DATA_BY_BIN_START = 'equipments/GET_DATA_BY_BIN_START'
export const GET_XML_TO_REORGANIZE_LOADING = 'equipments/GET_XML_TO_REORGANIZE_LOADING'
export const CLEAR_XML_TO_REORGANIZE = 'equipments/CLEAR_XML_TO_REORGANIZE'
export const SEND_SIGNED_XML_REORGANIZE = 'equipments/SEND_SIGNED_XML_REORGANIZE'
export const SET_REORGANIZE_SUCCESS = 'equipments/SET_REORGANIZE_SUCCESS'
export const REORGANIZE_ERROR = 'equipments/REORGANIZE_ERROR'
export const GET_DATA_BY_BIN_ERROR = 'equipments/GET_DATA_BY_BIN_ERROR'
export const CLEAR_DATA_BY_BIN = 'equipments/CLEAR_DATA_BY_BIN'
export const GET_EMPLOYERS_LIST_TO_CHANGE = 'equipments/GET_EMPLOYERS_LIST_TO_CHANGE'
export const GET_REORGANIZE_XML = 'equipments/GET_REORGANIZE_XML'
export const GET_FL_DATA = 'equipments/GET_FL_DATA'
export const GET_XML_FOR_UPDATE_IIN = 'equipments/GET_XML_FOR_UPDATE_IIN'
export const SEND_XML_FOR_UPDATE_IIN = 'equipments/SEND_XML_FOR_UPDATE_IIN'
export const RESET_UPDATE_IIN = 'equipments/RESET_UPDATE_IIN'
export const SET_ERROR_UPDATE_IIN = 'equipments/SET_ERROR_UPDATE_IIN'
export const SET_EDIT_SUCCESS = 'occupationalRisk/SET_EDIT_SUCCESS'
export const RESET_EDITING = 'occupationalRisk/RESET_EDITING'
export const SET_PARAMS = 'equipments/SET_PARAMS'

// PACKAGE LOADING
export const GET_XML_TO_SIGN_SOME_LOADING = 'equipments/GET_XML_TO_SIGN_SOME_LOADING'
export const GET_XML_TO_SIGN_SOME = 'equipments/GET_XML_TO_SIGN_SOME'
export const GET_XML_TO_SIGN_SOME_ERROR = 'equipments/GET_XML_TO_SIGN_SOME_ERROR'
export const SEND_SIGNED_XML_PACKAGE_SOME = 'equipments/SEND_SIGNED_XML_PACKAGE_SOME'
export const DELETE_PACKAGE_SOME = 'equipments/DELETE_PACKAGE_SOME'
export const RESET_PACKAGE = 'equipments/RESET_PACKAGE'
export const SET_IS_EDIT = 'equipments/SET_IS_EDIT'
