export const tableColumns = [
	{
		id: 'code',
		numeric: true,
		width: '22%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.id',
	},
	{
		id: 'name',
		numeric: false,
		width: '47%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.name',
	},
	{
		id: 'commissioningDate',
		numeric: true,
		width: '16%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.date_of',
	},
	{
		id: 'status',
		numeric: true,
		width: '12%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.rec_status',
	},
	{
		id: 'actionRow',
		numeric: false,
		width: '3%',
		label: '',
	},
]

export const tableColumnsFull = [
	{
		id: 'code',
		numeric: true,
		width: '13%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.id',
	},
	{
		id: 'name',
		numeric: false,
		width: '26%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.name',
	},
	{
		id: 'commissioningDate',
		numeric: true,
		width: '9%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.date_of',
	},
	{
		id: 'serviceLife',
		numeric: true,
		width: '9%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.service_life',
	},
	{
		id: 'isWithinServiceLife',
		numeric: false,
		width: '9%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.within_service_life',
	},
	{
		id: 'createDate',
		numeric: true,
		width: '9%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.create_date',
	},
	{
		id: 'updateDate',
		numeric: true,
		width: '9%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.update_date',
	},
	{
		id: 'bin',
		numeric: true,
		width: '11%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.bin',
	},
	{
		id: 'status',
		numeric: true,
		width: '11%',
		label: 'occupational_risks_assessment.regulatory_information.info_table.rec_status',
	},
	{
		id: 'actionRow',
		numeric: false,
		width: '5%',
		label: '',
	},
]

export const tableColumnsProtective = [
	{
		id: 'code',
		numeric: false,
		width: '14%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.id',
	},
	{
		id: 'nameKz',
		numeric: true,
		width: '20%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.name_kz',
	},
	{
		id: 'nameRu',
		numeric: true,
		width: '20%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.name_rus',
	},
	{
		id: 'createDate',
		numeric: true,
		width: '8%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.date_create',
	},
	{
		id: 'updateDate',
		numeric: true,
		width: '8%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.last_update',
	},
	{
		id: 'bin',
		numeric: true,
		width: '11%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.user',
	},
	{
		id: 'status',
		numeric: true,
		width: '8%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.rec_status',
	},
	{
		id: 'actionRow',
		numeric: false,
		width: '8%',
		label: '',
	},
]

export const tableColumnsProtectiveFull = [
	{
		id: 'code',
		numeric: false,
		width: '10%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.id',
	},
	{
		id: 'nameKz',
		numeric: true,
		width: '20%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.name_kz',
	},
	{
		id: 'nameRu',
		numeric: true,
		width: '20%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.name_rus',
	},
	{
		id: 'normativDocument',
		numeric: true,
		width: '6%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.normativ_document',
	},
	{
		id: 'issueRateInMonths',
		numeric: true,
		width: '5%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.rate_months',
	},
	{
		id: 'isCompliantWithStandards',
		numeric: true,
		width: '5%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.compliant_standards',
	},
	{
		id: 'isCert',
		numeric: true,
		width: '10%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.is_cert',
	},
	{
		id: 'createDate',
		numeric: true,
		width: '6%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.date_create',
	},
	{
		id: 'updateDate',
		numeric: true,
		width: '6%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.last_update',
	},
	{
		id: 'bin',
		numeric: true,
		width: '8%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.user',
	},
	{
		id: 'status',
		numeric: true,
		width: '8%',
		label: 'occupational_risks_assessment.regulatory_information.info_table_protective.rec_status',
	},
	{
		id: 'actionRow',
		numeric: false,
		width: '3%',
		label: '',
	},
]
