import styles from './steps.module.scss'
import { Step } from '@/components/Step/Step'
import { Button } from '@/ui'
import { useTranslation } from 'react-i18next'
import addWhiteIcon from '@/assets/pages/personalAffairs/addWhite.svg'
import { Table } from '@/entity/occupationalRisks/ui/components'
import { tableColumns } from '@/entity/occupationalRisks/ui/components/Table/tableColumns'
import React, { useState } from 'react'
import { OccupationalRisksModal } from '@/entity/occupationalRisks/ui/steps/components/OccupationalRisksModal/OccupationalRisksModal'

export const OccupationalRisksStepOne = () => {
	const { t } = useTranslation()
	const [isRequestModalOpen, setRequestModalOpen] = useState(false)

	const stepText = 'occupational_risks_assessment.steps.step_1.'

	return (
		<Step withoutHeight className={styles.step} notAccepted label={stepText + 'title'} index={1} opened={false}>
			{isRequestModalOpen && <OccupationalRisksModal onClose={() => setRequestModalOpen(false)} />}
			<div className={styles.pTandB}>
				<div className={styles.wrapper}>
					<div className={'flex'}>
						<Button
							buttonClassName={styles.mb2}
							icon={addWhiteIcon}
							onClick={() => setRequestModalOpen(!isRequestModalOpen)}
						>
							{t(stepText + 'add_object')}
						</Button>
					</div>
					<Table headCells={tableColumns} getData={''} data={[]} />
				</div>
			</div>
		</Step>
	)
}
