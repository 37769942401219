import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { ValidationSchema } from '@/entity'
import { Title } from '@/ui'
import styles from './ContractForm.module.scss'
import { request } from '@/shared/api/request'
import { Form } from './Form'
import { getDictionaries } from '@/shared/api/rest/dict/getDictionaries'
import { TemplateModal, NewTemplate, SaveTemplate, ContractProject } from './components'
import { LoadingBlocker } from '@/components/Loader'
import { message } from 'antd'
import { ConfirmContractModal } from '@features/contracts/actions'

const getTemplates = async (setTemplates, formData, setTemplatesLoading) => {
	try {
		setTemplatesLoading(true)
		const res = await request({ url: 'contract/econtract/template/list', method: 'POST', data: formData })
		setTemplates(res)
	} catch (error) {
		const err = error?.response?.data?.message || 'Неизвестная ошибка'
		message.error(err ? err : 'Неизвестная ошибка', 5)
	} finally {
		setTemplatesLoading(false)
	}
}

export const ContractForm = ({
	initialValues,
	options = {},
	advanced,
	onSubmit,
	onCancel,
	formRef,
	isTitle,
	setIsEsutd,
	isEsutd,
	isEdit,
	isElectronicButton = false,
	forceSubmitEdit,
	setForceSubmitEdit,
	attachProjectFile,
	CloseAndUpdateFilter,
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const [isFormDirty, setFormDirty] = useState(false)
	const [preparedData, setPreparedData] = useState(false)
	const [submitData, setSubmitData] = useState(false)

	const { positionsByKnz = [] } = options

	const [professionalSkills, setProfessionalSkills] = useState(null)
	const [personalQualities, setPersonalQualities] = useState(null)
	const [filesMiddleWare, setFilesMiddleWare] = useState([])
	const { files } = initialValues
	const getProfessionalSkills = async () => {
		const { content } = await getDictionaries('specCompetence', { size: 99999 })
		if (!isEdit) {
			let filterContent = content.filter((item) => {
				return item.isActual === 1 && item.fndChild === 0
			})
			setProfessionalSkills(filterContent || [])
		} else {
			setProfessionalSkills(content || [])
		}
	}

	const getPersonalQualities = async () => {
		const { content } = await getDictionaries('competence', { size: 99999 })
		if (!isEdit) {
			let filterContent = content.filter((item) => {
				return item.isActual === 1 && item.fndChild === 0
			})
			setPersonalQualities(filterContent || [])
		} else {
			setPersonalQualities(content || [])
		}
	}

	useEffect(() => {
		if (!professionalSkills) {
			getProfessionalSkills()
		}
	}, [professionalSkills])

	useEffect(() => {
		if (!personalQualities) {
			getPersonalQualities()
		}
	}, [personalQualities])

	/*const isNonResidentPermitNumber = isEdit
		? initialValues?.foreignLaborInfo?.nonResidentPermitNumber
		: formRef?.current?.values?.foreignLaborInfo?.nonResidentPermitNumber*/

	const _onSubmit = (esutd) => {
		!formRef?.current?.values?.dateFrom && formRef?.current?.setFieldError('dateFrom', t('validation.required'))
		//!formRef?.current?.values?.registerDate && formRef?.current?.setFieldError('registerDate', t('validation.required'))
		setIsEsutd(esutd)
		setFormDirty(true)
	}

	const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false)
	const [isOpenNewTemplateModal, setIsOpenNewTemplateModal] = useState(false)
	const [isOpenContractProjectModal, setIsOpenContractProjectModal] = useState(false)
	const [isOpenSaveTemplateModal, setIsOpenSaveTemplateModal] = useState(false)

	const [formData, setFormData] = useState({})
	const [selectedTemplate, setSelectedTemplate] = useState()
	const [html, setHtml] = useState(null)
	const [lang, setLang] = useState('rus')

	useEffect(() => {
		if (!isOpenNewTemplateModal) {
			setSelectedTemplate(null)
		}
	}, [isOpenNewTemplateModal])

	const handleOpenTemplateModal = (values) => {
		getTemplates(setTemplates, values, setTemplatesLoading)
		setFormData(values)
		if (values.dcontractDateCode === '9') {
			setIsOpenNewTemplateModal(true)
		} else {
			setIsOpenTemplateModal(true)
		}
	}

	const handleOpenNewTemplateModal = () => {
		setIsOpenTemplateModal(false)
		setIsOpenNewTemplateModal(true)
	}

	const getOptionsDtos = async (newtemplateData) => {
		try {
			const optionDtos = []

			const options = await request({ url: '/contract/econtract/dict/options', method: 'GET' })

			for (const key in newtemplateData) {
				const item = newtemplateData[key]

				const optionItem = options.find((option) => option.code == key)

				if (optionItem) {
					if (optionItem.code == 'employeeResp' && newtemplateData.employeeRespIs) {
						optionDtos.push({
							paramValueCode: '35',
							textValueKaz: newtemplateData.employeeResp,
							textValueRus: newtemplateData.employeeResp,
						})
					} else if (optionItem.code == 'employeeRights' && newtemplateData.employeeRightsIs) {
						optionDtos.push({
							paramValueCode: '34',
							textValueKaz: newtemplateData.employeeRights,
							textValueRus: newtemplateData.employeeRights,
						})
					} else if (optionItem.code == 'employerResp' && newtemplateData.employerRespIs) {
						optionDtos.push({
							paramValueCode: '37',
							textValueKaz: newtemplateData.employerResp,
							textValueRus: newtemplateData.employerResp,
						})
					} else if (optionItem.code == 'employerRights' && newtemplateData.employerRightsIs) {
						optionDtos.push({
							paramValueCode: '36',
							textValueKaz: newtemplateData.employerRights,
							textValueRus: newtemplateData.employerRights,
						})
					} else if (optionItem.code == 'downtime' && newtemplateData.downtime) {
						optionDtos.push({
							paramValueCode: '11.1',
						})
					} else if (optionItem.code == 'overtime' && newtemplateData.overtime) {
						optionDtos.push({
							paramValueCode: '12.1',
						})
					} else {
						optionDtos.push({
							paramValueCode: item,
						})
					}
				}
			}

			return optionDtos
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		}
	}

	const handleOpenContractProjectModal = async (newtemplateData) => {
		setSelectedTemplate(newtemplateData)
		const originalParams = {}
		if (newtemplateData?.id) {
			originalParams.templateId = newtemplateData?.id
		}
		setNewtemplateLoading(true)
		const optionDtos = await getOptionsDtos(newtemplateData)
		try {
			const res = await request({
				url: '/contract/econtract/html',
				method: 'POST',
				data: {
					...formData,
					surname: formData.surname,
					name: formData.name,
					middleName: formData.middlename,
					optionDtos: newtemplateData.id ? undefined : optionDtos.filter((item) => Boolean(item.paramValueCode)),
				},
				params: originalParams,
			})
			setHtml(res.valueRu || res.valueKz)
			setLang(res.valueRu ? 'rus' : 'kaz')
			setIsOpenContractProjectModal(true)
		} catch (error) {
			alert('Мәтіндік жолақтарды толтырыңыз!/Заполните текстовые поля!')
		} finally {
			setNewtemplateLoading(false)
		}
	}

	const [templates, setTemplates] = useState(null)

	const [newtemplateData, setNewtemplateData] = useState({})
	const [newtemplateLoading, setNewtemplateLoading] = useState(false)

	const handleOpenSaveTemplateModal = (data) => {
		setNewtemplateData(data)
		setIsOpenSaveTemplateModal(true)
	}

	const createNewTemplate = async (templateName) => {
		try {
			setNewtemplateLoading(true)
			const optionDtos = await getOptionsDtos(newtemplateData)
			const body = {
				createDate: selectedTemplate?.createDate,
				id: selectedTemplate?.id,
				optionDtos: optionDtos.filter((item) => Boolean(item.paramValueCode)),
				templateName,
			}
			const res = await request({ url: '/contract/econtract/template', method: 'POST', data: body })
			setSelectedTemplate(res)
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setNewtemplateLoading(false)
			setIsOpenSaveTemplateModal(false)
			getTemplates(setTemplates, formData, setTemplatesLoading)
		}
	}
	const [specSkillsListMessage, setSpecSkillsListMessage] = useState(null)
	const [skillsListMessage, setSkillsListMessage] = useState(null)
	const [positionsByKnzMessage, setPositionsByKnzMessage] = useState(null)

	const attachHarmfulConditionsFiles = (values) => {
		setFilesMiddleWare(values)
	}

	const onSubmitMiddleWare = (data) => {
		const messages = {
			specSkillsList: {
				rus: 'У Вас имеются неактуальные профессиональные навыки, рекомендуется удалить все имеющиеся профессиональные навыки и добавить их заново из актуального справочника.',
				kz: 'Сізде өзекті емес кәсіби дағдылар бар, барлық қолда бар кәсіби дағдыларды жою және оларды өзекті анықтамалықтан қайта қосу ұсынылады.',
			},
			skillsList: {
				rus: 'У Вас имеются неактуальные личные качества, рекомендуется удалить все имеющиеся личные качества и добавить их заново из актуального справочника.',
				kz: 'Сізде маңызды емес жеке қасиеттер бар, барлық қол жетімді жеке қасиеттерді алып тастап, оларды қазіргі каталогтан қайта қосу ұсынылады.',
			},
			positionsByKnz: {
				rus: 'Должность не найдена в справочнике',
				kz: 'Лауазым анықтамалықта табылмады',
			},
		}

		let isSubmitAvaliable = true

		setSpecSkillsListMessage(null)
		setSkillsListMessage(null)
		setPositionsByKnzMessage(null)

		const specSkillsList = data.specSkillsList

		for (let i = 0; i < specSkillsList.length; i++) {
			const element = specSkillsList[i]

			const fullElement = professionalSkills.find((item) => item.code == element)
			if (fullElement?.isActual == 0 || fullElement?.fndChild == 1) {
				isSubmitAvaliable = false
				setSpecSkillsListMessage(messages.specSkillsList[language])
				break
			}
		}
		const skillsList = data.skillsList

		for (let i = 0; i < skillsList.length; i++) {
			const element = skillsList[i]

			const fullElement = personalQualities.find((item) => item.code == element)
			if (fullElement?.isActual == 0 || fullElement?.fndChild == 1) {
				isSubmitAvaliable = false
				setSkillsListMessage(messages.skillsList[language])
				break
			}
		}

		const selectedpositionsByKnz = positionsByKnz.find((item) => item.code == data.dpositionCode)

		if (!selectedpositionsByKnz) {
			isSubmitAvaliable = false
			setPositionsByKnzMessage(messages.positionsByKnz[language])
		}

		if (isSubmitAvaliable) {
			setForceSubmitEdit && setForceSubmitEdit(false)
			setSpecSkillsListMessage(null)
			setSkillsListMessage(null)
			setPositionsByKnzMessage(null)
			let sendFileArray = []
			let harmfulFields = []
			if (data.dworkingConditionsCode && data.dworkingConditionsCode !== '0') {
				if (filesMiddleWare.length > 0) {
					sendFileArray = filesMiddleWare.map((file) => ({
						id: file.id,
					}))
				}
			} else {
				harmfulFields = ['dharmfulConditionsTypeCode']
			}

			// Фильтрация данных, исключая ненужные поля
			const excludedFields = ['requiredList', 'dpositionCodeList', 'positionParents']

			let filteredData = Object.keys(data)
				.filter((key) => !excludedFields.some((excludedField) => key.startsWith(excludedField)))
				.filter((key) => !harmfulFields.some((harmfulField) => key.includes(harmfulField)))
				.reduce((obj, key) => {
					obj[key] = data[key]
					return obj
				}, {})

			// Включение массива файлов в объект данных
			setSubmitData({ ...filteredData, files: sendFileArray || [] })

			// Вызов onSubmit с подготовленными данными
			preparedData && onSubmit({ ...filteredData, files: sendFileArray || [] })
		}
	}

	useEffect(() => {
		if (forceSubmitEdit && professionalSkills && personalQualities) {
			onSubmitMiddleWare(initialValues)
		}
	}, [forceSubmitEdit, professionalSkills, personalQualities])

	const selectTemplate = (template) => {
		handleOpenContractProjectModal(template)
	}

	const [templatesLoading, setTemplatesLoading] = useState(false)

	const handleSendToEmployee = async (data, isSendToEmployee, CloseAndUpdateFilter) => {
		try {
			const optionDtos = await getOptionsDtos(selectedTemplate)
			setTemplatesLoading(true)
			const response = await request({
				url: '/contract/econtract/save',
				method: 'POST',
				data: {
					contractDTO: {
						...formData,
						optionDtos: selectedTemplate?.id ? undefined : optionDtos.filter((item) => Boolean(item.paramValueCode)),
					},
					templateId: selectedTemplate?.id,
					valueList: data,
				},
				params: {
					isSend: isSendToEmployee,
				},
			})
			if (response.success) CloseAndUpdateFilter()
			message.success(t('success_alert'))
			onCancel()
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			message.error(err ? err : 'Неизвестная ошибка', 5)
		} finally {
			setTemplatesLoading(false)
		}
	}

	return (
		<div>
			{(newtemplateLoading || templatesLoading) && <LoadingBlocker />}
			{isTitle && (
				<Title color="black">
					{isElectronicButton ? t('draft_etd') : t('contracts_tab.registration_new_employer_contract')}
				</Title>
			)}
			{isOpenTemplateModal && (
				<TemplateModal
					isOpen={isOpenTemplateModal}
					onClose={setIsOpenTemplateModal.bind(null, false)}
					onNewTemplate={handleOpenNewTemplateModal}
					formData={formData}
					selectTemplate={selectTemplate}
					templates={templates}
					getTemplates={getTemplates}
					setTemplates={setTemplates}
				/>
			)}
			{isOpenNewTemplateModal && (
				<NewTemplate
					isOpen={isOpenNewTemplateModal}
					onClose={setIsOpenNewTemplateModal.bind(null, false)}
					onSave={handleOpenSaveTemplateModal}
					onOpenProject={handleOpenContractProjectModal}
					formData={formData}
				/>
			)}
			<ContractProject
				isOpen={isOpenContractProjectModal}
				onClose={setIsOpenContractProjectModal.bind(null, false)}
				onSave={handleOpenSaveTemplateModal}
				handleSendToEmployee={handleSendToEmployee}
				selectedTemplate={selectedTemplate}
				formData={formData}
				htmlData={html}
				lang={lang}
				CloseAndUpdateFilter={CloseAndUpdateFilter}
			/>
			<SaveTemplate
				isOpen={isOpenSaveTemplateModal}
				onClose={setIsOpenSaveTemplateModal.bind(null, false)}
				onConfirm={createNewTemplate}
				selectedTemplate={selectedTemplate}
			/>
			<Formik
				validationSchema={ValidationSchema}
				className={styles.form}
				initialValues={initialValues}
				onSubmit={onSubmitMiddleWare}
				innerRef={formRef}
			>
				<Form
					advanced={advanced}
					isEdit={isEdit}
					isElectronicButton={isElectronicButton}
					options={options}
					onCancel={onCancel}
					setPreparedData={setPreparedData}
					onSubmitData={onSubmit}
					submitData={submitData}
					setIsEsutd={setIsEsutd}
					setFormDirty={setFormDirty}
					onOpenTemplateModal={handleOpenTemplateModal}
					_onSubmit={_onSubmit}
					specSkillsListMessage={specSkillsListMessage}
					skillsListMessage={skillsListMessage}
					positionsByKnzMessage={positionsByKnzMessage}
					isFormDirty={isFormDirty}
					personalQualities={personalQualities}
					professionalSkills={professionalSkills}
					attachProjectFile={attachProjectFile}
					attachHarmfulConditionsFiles={attachHarmfulConditionsFiles}
					files={files}
				/>
			</Formik>
			<div></div>
		</div>
	)
}
