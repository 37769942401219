import * as types from './types'
import { GET_BRANCHES_LOADING, GET_HARMFUL_LOADING, GET_STAFF_LOADING } from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setOccupationalRisk: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISKS,
		payload,
	}),
	setOccupationalRiskLoading: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISK_LOADING,
		payload,
	}),
	setOccupationalRiskSuccess: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISK_SUCCESS,
		payload,
	}),
	setOccupationalRisks: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISKS,
		payload,
	}),
	setCreateOccupationalRiskXML: (payload) => ({
		type: types.SET_CREATE_OCCUPATIONAL_RISK_XML,
		payload,
	}),
	setRemoveXML: (payload) => ({
		type: types.SET_REMOVE_XML,
		payload,
	}),
	setRecoverXML: (payload) => ({
		type: types.SET_RECOVER_XML,
		payload,
	}),
	setEditXML: (payload) => ({
		type: types.SET_EDIT_XML,
		payload,
	}),
	getDataByBinStart: (payload) => ({
		type: types.GET_DATA_BY_BIN_START,
		payload,
	}),
	getDataByBin: (payload) => ({
		type: types.GET_DATA_BY_BIN,
		payload,
	}),
	getDataByBinError: (payload) => ({
		type: types.GET_DATA_BY_BIN_ERROR,
		payload,
	}),
	clearDataByBin: (payload) => ({
		type: types.CLEAR_DATA_BY_BIN,
		payload,
	}),
	setInitialOccupationalRisk: () => ({
		type: types.SET_INITIAL_OCCUPATIONAL_RISK,
	}),
	setOccupationalRiskFields: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISK_FIELDS,
		payload,
	}),
	setEditSuccess: (payload) => ({
		type: types.SET_EDIT_SUCCESS,
		payload,
	}),
	resetEditing: () => ({
		type: types.RESET_EDITING,
	}),
	setParams: (payload) => ({
		type: types.SET_PARAMS,
		payload,
	}),
	setSelectedOccupationalRisks: (payload) => ({
		type: types.SET_SELECTED_OCCUPATIONAL_RISKS_STATE,
		payload,
	}),
	getBranches: (payload) => ({
		type: types.GET_BRANCHES,
		payload,
	}),
	getBranchesLoading: (payload) => ({
		type: types.GET_BRANCHES_LOADING,
		payload,
	}),
	getStaff: (payload) => ({
		type: types.GET_STAFF,
		payload,
	}),
	getStaffLoading: (payload) => ({
		type: types.GET_STAFF_LOADING,
		payload,
	}),
	getHarmfulLoading: (payload) => ({
		type: types.GET_HARMFUL_LOADING,
		payload,
	}),
	getHarmfulSuccess: (payload) => ({
		type: types.GET_HARMFUL_SUCCESS,
		payload,
	}),
	// PACKAGE LOADING
	getXmlToSignSomeLoading: () => ({ type: types.GET_XML_TO_SIGN_SOME_LOADING }),
	getXmlToSignSome: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME,
		payload,
	}),
	getXmlToSignSomeError: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME_ERROR,
		payload,
	}),
	sendSignedXmlPackageSome: (payload) => ({
		type: types.SEND_SIGNED_XML_PACKAGE_SOME,
		payload,
	}),
	deleteOccupationalRisks: (payload) => ({
		type: types.DELETE_OCCUPATIONAL_RISK_SOME,
		payload,
	}),
	resetPackage: (payload) => ({
		type: types.RESET_OCCUPATIONAL_RISK,
		payload,
	}),

	setOccupationalRiskError: (payload) => ({
		type: types.SET_OCCUPATIONAL_RISK_ERROR,
		payload,
	}),
	resetOccupationalRisk: () => ({
		type: types.RESET_OCCUPATIONAL_RISK,
	}),
	setIsEdit: (payload) => ({
		type: types.SET_IS_EDIT,
		payload,
	}),
}
