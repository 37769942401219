import * as React from 'react'
import TabsUnstyled from '@mui/base/Tabs'
import { styled } from '@mui/system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Table } from '@/entity/occupationalRisks/ui/components/Table/Table'
import styles from './TreeTableChild.module.scss'
import { Add } from '@app/icons'
import { Button } from '@/ui'
import { tableColumnsEq } from '@/entity/occupationalRisks/ui/components/TreeTableChild/tableTreeColumns'
const Tabs = styled(TabsUnstyled)({})

export function TreeEquipments() {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState(true)
	const handleChangeTab = (e, newValue) => setActiveTab(newValue)
	return (
		<div className={styles.treeEquipments}>
			<Grid
				paddingX={2}
				paddingY={1}
				className={styles.addButton}
				container
				columns={12}
				justifyContent="space-between"
			>
				<Grid item xs={3}>
					{t('occupational_risks_assessment.steps.step_2.equipments')}
				</Grid>
				<Grid textAlign="end" item xs={2}>
					<Button transparent buttonClassName={styles.button}>
						<Add className={styles.iconGreen} />
						{t('add')}
					</Button>
				</Grid>
			</Grid>
			<Grid paddingX={2}>
				<Table notHr className={styles.treeChild} headCells={tableColumnsEq} getData={''} data={[]} />
			</Grid>
		</div>
	)
}
