import styles from './steps.module.scss'
import { Step } from '@/components/Step/Step'
import { ListOfEquipment, ProvisionPPE } from '@/entity/occupationalRisks/ui/form/components'
import { Button } from '@/ui'
import { Plus } from '@app/images'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { OccupationalRisksModalFive } from '@/entity/occupationalRisks/ui/steps/components/OccupationalRisksModalFive/OccupationalRisksModalFive'
import { useState } from 'react'

export const OccupationalRisksStepFive = () => {
	const { t } = useTranslation()
	const { setFieldValue, values, validateForm } = useFormikContext()
	const [isOpenModal, setOpenModal] = useState(false)
	const stepText = 'occupational_risks_assessment.steps.step_5.'

	return (
		<Step
			classNameContent={styles.pTandB}
			withoutHeight
			className={styles.step}
			notAccepted
			label={stepText + 'title'}
			index={5}
			opened={false}
		>
			{isOpenModal && <OccupationalRisksModalFive onClose={() => setOpenModal(false)} />}
			<div className={styles.pTandB}>
				<Grid container justifyContent="flex-start">
					<Button onClick={() => setOpenModal(!isOpenModal)} type="button">
						{t(stepText + 'create')}
					</Button>
				</Grid>
			</div>
		</Step>
	)
}
