import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import OccupationalRisksContent from '@/entity/occupationalRisks/ui/OccupationalRisksContent'

export const OccupationalRisksAssessment = () => {
	const { t } = useTranslation()
	const onSubmit = (data) => {
		console.log(data)
	}
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_occupational_risks_assessment')}>
				{/*<Formik
					initialValues={{ objectAssessment: [0], objectORA: [0], membersOfCommissionORA: [0] }}
					validationSchema={ValidationSchemaOccupationalRisks}
					onSubmit={onSubmit}
				>
					<OccupationalRisksAssessmentForm />
				</Formik>*/}
				<OccupationalRisksContent />
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
