import { Formik, Form as FormikForm, FieldArray } from 'formik'
import styles from './EquipmentModal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'
import { useDigitalSign } from '@/shared'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingBlocker } from '@/components/Loader'
import { Plus } from '@app/images'
import { Grid } from '@mui/material'
import { EquipmentForm } from '@/entity/regulatoryInformation/ui/tabs/components/EquipmentModal/form/EquipmentForm'
import { initialEquipment } from '@/entity/regulatoryInformation/model/equipment/reducer'
import { equipmentThunks, ValidationSchemaEquipment } from '@/entity'

const initialDictObject = {
	commissioningDate: '',
	isIndefinitely: true,
	isWithinServiceLife: true,
	name: '',
	serviceLife: null,
}

export const EquipmentModal = ({ page = 0, isEdit, size = 10, onClose }) => {
	const { t } = useTranslation()
	const { isLoading } = useDigitalSign()
	const [isFormDirty, setFormDirty] = useState(false)
	const dispatch = useDispatch()
	const { dicts } = useSelector((state) => state.equipment)
	const [sendingLoading, setSendingLoading] = useState(false)

	const submitForm = (data) => {
		isEdit ? dispatch(equipmentThunks.edit(data)) : dispatch(equipmentThunks.createEquipment(data))
		onClose()
	}

	return (
		<>
			{(isLoading || sendingLoading) && <LoadingBlocker />}

			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>
							{t('occupational_risks_assessment.regulatory_information.equipment_modal.title')}
						</Title>
					</div>
					<Formik
						initialValues={{ dicts } ?? initialEquipment}
						validationSchema={ValidationSchemaEquipment}
						onSubmit={submitForm}
					>
						{({ values }) => {
							return (
								<FormikForm>
									<FieldArray name="dicts">
										{({ remove, push }) => (
											<>
												{values.dicts.map((item, index) => (
													<EquipmentForm
														key={index}
														remove={remove}
														onClose={onClose}
														isFormDirty={isFormDirty}
														name={'dicts'}
														index={index}
														showIndex={values.dicts.length > 1}
													/>
												))}
												{!isEdit && (
													<Button
														textColor="green"
														borderColor="green"
														transparent
														border
														type="button"
														icon={Plus}
														onClick={push.bind(null, initialDictObject)}
													>
														{t('occupational_risks_assessment.add_equip')}
													</Button>
												)}
											</>
										)}
									</FieldArray>
									<Grid container justifyContent="flex-end">
										<Button onClick={() => setFormDirty(true)} type="submit">
											{t('save')}
										</Button>
									</Grid>
								</FormikForm>
							)
						}}
					</Formik>
				</div>
			</Modal>
		</>
	)
}
