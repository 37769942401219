import { useHistory } from 'react-router-dom'
import { Formik, Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	OccupationalRisksStepOne,
	OccupationalRisksStepTwo,
	OccupationalRisksStepThree,
	OccupationalRisksStepFour,
	OccupationalRisksStepFive,
	OccupationalRisksStepSix,
} from '@/entity/occupationalRisks/ui/steps'
import styles from './style.module.scss'
import { Arrow } from '@app/icons'

export const NewOccupationalRisksAssessmentForm = () => {
	const { t } = useTranslation()
	const history = useHistory()

	return (
		<>
			<div onClick={() => history.push('/applications-ora')} className={styles.back}>
				<Arrow styles={{ fill: '#17B67C', width: '5px', marginRight: '5px' }} />
				{t('back')}
			</div>
			<FormikForm>
				<div>
					<OccupationalRisksStepOne />
					<OccupationalRisksStepTwo />
					<OccupationalRisksStepThree />
					<OccupationalRisksStepFour />
					<OccupationalRisksStepFive />
				</div>
			</FormikForm>
		</>
	)
}
