import React, { useCallback } from 'react'
import styles from './TwoRadio.module.scss'
import { Info } from '@/app/icons'
import { Tooltip } from 'antd'
import { Label } from '@/ui/atoms'
import cn from 'classnames'
import RadioStaff from '@/components/RadioStaff/RadioStaff'
import { useTranslation } from 'react-i18next'

export const TwoRadio = ({
	name,
	value,
	onChange = function () {},
	isBoolean,
	label,
	firstId,
	secondId,
	labelFirstRadio = 'yes',
	labelSecondRadio = 'no',
	isRequired,
	labelSize = 'default',
	labelClassName = '',
	hint = '',
}) => {
	const { t } = useTranslation()
	const handleChange = useCallback(
		(e, checked) => {
			const { value } = e.target
			isBoolean ? onChange(checked) : onChange(value)
		},
		[onChange, isBoolean]
	)

	return (
		<label htmlFor={name} className={styles.container}>
			<div className={'flex'}>
				<RadioStaff
					label={t(labelFirstRadio)}
					value={value}
					id={firstId}
					checked={isBoolean ? value : value === firstId}
					onChange={(e) => handleChange(e, true)}
				/>
			</div>
			<div className={'flex'}>
				<RadioStaff
					label={t(labelSecondRadio)}
					value={value}
					id={secondId}
					checked={isBoolean ? !value : value === secondId}
					onChange={(e) => handleChange(e, false)}
				/>
			</div>
			{hint ? (
				<Tooltip placement="top" color="white" overlayInnerStyle={{ color: '#5B6880', width: '329px' }} title={hint}>
					<label name={name}>
						{label}
						{isRequired ? ' *' : ''}
						<Info className={styles.hintIcon} />
					</label>
				</Tooltip>
			) : (
				<Label name={name} size={labelSize} className={labelClassName}>
					{label}
					{isRequired ? ' *' : ''}
				</Label>
			)}
		</label>
	)
}
