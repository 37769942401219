import React, { useCallback } from 'react'
import styles from './Checkbox.module.scss'
import { Info } from '@/app/icons'
import { Tooltip } from 'antd'
import { Label } from '@/ui/atoms'
import cn from 'classnames'
import classNames from 'classnames'

export const Checkbox = ({
	name,
	className,
	classNameCheckbox,
	onChange = function () {},
	value = false,
	label,
	isRequired,
	labelSize = 'default',
	labelClassName = '',
	disabled = false,
	disableLabelClick = false,
	hint = '',
}) => {
	const handleClick = useCallback(() => {
		onChange(!value)
	}, [disabled, disableLabelClick, onChange])

	return (
		<label htmlFor={name} className={classNames(styles.container, className, classNameCheckbox)}>
			<input onChange={handleClick} id={name} type="checkbox" checked={value} disabled={disabled} />
			<span className={styles.checkmark} />
			{hint ? (
				<Tooltip
					onClick={handleClick}
					placement="top"
					color="white"
					overlayInnerStyle={{ color: '#5B6880', width: '329px' }}
					title={hint}
				>
					<label name={name}>
						{label}
						{isRequired ? ' *' : ''}
						<Info className={styles.hintIcon} />
					</label>
				</Tooltip>
			) : (
				<Label name={name} size={labelSize} className={labelClassName}>
					{label}
					{isRequired ? ' *' : ''}
				</Label>
			)}
		</label>
	)
}
