import React from 'react'
import styles from './PensionPersons.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Button, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import Table from './Table/Table'
import { Tooltip } from 'antd'
import { Info } from '@app/icons'

const PensionPersons = ({ getPensioner, onClose }) => {
	const { t } = useTranslation()
	return (
		<Modal windowClassName={styles.modal}>
			<ModalBackButton onClick={onClose} />
			<div className={`${styles.wrapper} scroll`}>
				<Title className={styles.title}>
					{t('pension.persons')}
					<Tooltip
						placement="rightTop"
						color="white"
						overlayInnerStyle={{ color: '#5B6880' }}
						title={t('pension.payments.hints.pension_persons')}
					>
						<span>
							<Info className={styles.hintIcon} />
						</span>
					</Tooltip>
				</Title>
			</div>
			<Table getPensioner={getPensioner} onClose={onClose}></Table>
			<Button borderColor="green" buttonClassName={styles.closeButton} onClick={onClose}>
				{t('pension.model.close')}
			</Button>
		</Modal>
	)
}

export default PensionPersons
