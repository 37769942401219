import React from 'react'
import { useTranslation } from 'react-i18next'
import './style.css'
export const Tags = ({ tags, chosen, setChosen, onlyTags = false }) => {
	const {
		i18n: { language },
	} = useTranslation()
	const buttonChosen = (code) => {
		if (chosen && chosen.includes(code)) return 'button active'
		else return 'button'
	}
	const addToList = (code) => {
		if (chosen && chosen.includes(code))
			setChosen(chosen.length === 1 ? ['all'] : chosen.filter((item) => item !== code))
		else setChosen(code === 'all' ? ['all'] : chosen.filter((item) => item !== 'all').concat(code))
	}
	return (
		<div>
			{tags.map((tag) => {
				return onlyTags ? (
					<button className="button active">{language === 'rus' ? tag.nameRu : tag.nameKz}</button>
				) : (
					<button className={buttonChosen(tag.code)} key={tag.code} onClick={() => addToList(tag.code)}>
						#{language === 'rus' ? tag.nameRu : tag.nameKz}
					</button>
				)
			})}
		</div>
	)
}
