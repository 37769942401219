import styles from './ImportModal.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { equipmentThunks, protectiveEquipmentThunks } from '@/entity'
import DropzoneFile from '@/ui/atoms/dropzoneFile'

export const ImportModal = ({ onClose, equipment }) => {
	const { t } = useTranslation()
	const baseText = 'occupational_risks_assessment.regulatory_information.'
	const dispatch = useDispatch()
	const [uploadedFile, setUploadedFile] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')

	const uploadFile = (isReplace) => {
		if (!uploadedFile) {
			setErrorMessage('Сначала выберите файл для отправки.')
			return
		}
		const formData = new FormData()
		formData.append('file', uploadedFile)
		equipment
			? dispatch(equipmentThunks.importFile(formData, isReplace))
			: dispatch(protectiveEquipmentThunks.importFile(formData, isReplace))
	}

	return (
		<>
			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>
							{t('occupational_risks_assessment.regulatory_information.equipment_modal.title')}
						</Title>
					</div>
					<Grid mb={4}>
						<DropzoneFile
							errorMessage={errorMessage}
							setErrorMessage={setErrorMessage}
							uploadedFile={uploadedFile}
							setUploadedFile={setUploadedFile}
						/>
					</Grid>
					<Grid container justifyContent="flex-end">
						<Grid mr={4}>
							<Button transparent textColor="grey" onClick={onClose} type="submit">
								{t('cancel')}
							</Button>
						</Grid>
						<Grid mr={4}>
							<Button disabled={!uploadedFile || errorMessage} onClick={() => uploadFile(true)} type="submit">
								{t(baseText + 'replace_all_dict')}
							</Button>
						</Grid>
						<Button disabled={!uploadedFile || errorMessage} onClick={() => uploadFile(false)} type="submit">
							{t(baseText + 'add_new_rec')}
						</Button>
					</Grid>
				</div>
			</Modal>
		</>
	)
}
