import React from 'react'

export const ThreeDots = ({ className }) => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.834 5.41406C10.834 6.10406 10.274 6.66406 9.58398 6.66406C8.89398 6.66406 8.33398 6.10406 8.33398 5.41406C8.33398 4.72406 8.89398 4.16406 9.58398 4.16406C10.274 4.16406 10.834 4.72406 10.834 5.41406Z"
				fill="#1D293F"
			/>
			<path
				d="M9.58398 8.53906C8.89398 8.53906 8.33398 9.09906 8.33398 9.78906C8.33398 10.4791 8.89398 11.0391 9.58398 11.0391C10.274 11.0391 10.834 10.4791 10.834 9.78906C10.834 9.09906 10.274 8.53906 9.58398 8.53906Z"
				fill="#1D293F"
			/>
			<path
				d="M9.58398 12.9141C8.89398 12.9141 8.33398 13.4741 8.33398 14.1641C8.33398 14.8541 8.89398 15.4141 9.58398 15.4141C10.274 15.4141 10.834 14.8541 10.834 14.1641C10.834 13.4741 10.274 12.9141 9.58398 12.9141Z"
				fill="#1D293F"
			/>
		</svg>
	)
}
