import { equipmentActions, equipmentThunks } from '@/entity'
import { handbookThunks } from '@/entity/handbook'
import { api } from '@/shared'
import { message } from 'antd'
import { actions } from './actions'
import { filterOneKato } from '@/entity/handbook/thunks'
import { sendFile } from '@/shared/api/rest/regulatoryInformation/protectiveEquipment'

export const createEquipment = (data, isEdit = false) => {
	return async (dispatch) => {
		try {
			const createData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'create',
				})),
			}
			dispatch(equipmentActions.setEquipmentLoading(true))
			const created = await api.regulatoryInformation.equipment.createEditEquipment(isEdit ? data : createData)
			if (created.success) {
				dispatch(equipmentActions.setEquipmentSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(equipmentActions.setEquipmentSuccess(false))
			}
			dispatch(equipmentThunks.getEquipments())
		} catch (error) {
			dispatch(equipmentActions.setEquipmentSuccess(false))
			dispatch(equipmentActions.setEquipmentLoading(false))
			message.error('Ошибка сохранения данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const getEquipment = (object) => {
	return async (dispatch) => {
		try {
			const equipment = [object]
			dispatch(equipmentActions.setEquipmentLoading(true))
			dispatch(equipmentActions.setEditEquipment(equipment))
		} catch (error) {
			dispatch(equipmentActions.setEquipmentLoading(false))
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const getEquipments = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(equipmentActions.toggleError(null))
			dispatch(equipmentActions.toggleLoading(true))
			dispatch(equipmentActions.setParams(params))
			const data = await api.regulatoryInformation.equipment.getAll({ ...params, page, size })
			dispatch(equipmentActions.setEquipments(data))
			dispatch(equipmentActions.toggleSuccess(true))
			dispatch(equipmentActions.toggleLoading(false))
		} catch (error) {
			dispatch(equipmentActions.toggleError(error))
			dispatch(equipmentActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(equipmentActions.toggleLoading(false))
		}
	}
}

export const edit = (data) => {
	return async (dispatch) => {
		try {
			dispatch(equipmentActions.setEquipmentLoading(true))
			const editData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'update',
				})),
			}
			dispatch(equipmentThunks.createEquipment(editData, true))
			dispatch(equipmentActions.setEquipmentSuccess(true))
			dispatch(equipmentActions.setEquipmentLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(equipmentActions.resetEditing())
			dispatch(equipmentActions.setEquipmentLoading(false))
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const remove = (data) => {
	return async (dispatch) => {
		try {
			dispatch(equipmentActions.setEquipmentLoading(true))
			const editData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'delete',
				})),
			}
			dispatch(equipmentThunks.createEquipment(editData, true))
			dispatch(equipmentActions.setEquipmentSuccess(true))
			dispatch(equipmentActions.setEquipmentLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(equipmentActions.resetEditing())
			dispatch(equipmentActions.setEquipmentLoading(false))
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const importFile = (data, isReplace) => {
	return async (dispatch) => {
		try {
			dispatch(equipmentActions.setEquipmentLoading(true))
			const params = {
				dictName: 'equipment',
				isReplace,
			}
			const sendFile = await api.regulatoryInformation.protectiveEquipment.sendFile(params, data)
			if (sendFile.success) {
				dispatch(equipmentActions.setEquipmentSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(equipmentActions.setEquipmentSuccess(false))
			}
			dispatch(equipmentThunks.getEquipments())
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response : 'Неизвестная ошибка')
			dispatch(equipmentActions.setEquipmentLoading(false))
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const downloadFile = () => {
	return async (dispatch) => {
		try {
			dispatch(equipmentActions.setEquipmentLoading(true))
			const res = await api.regulatoryInformation.equipment.getDownload()
			console.log(res)
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response : 'Неизвестная ошибка')
			dispatch(equipmentActions.setEquipmentLoading(false))
		} finally {
			dispatch(equipmentActions.setEquipmentLoading(false))
		}
	}
}

export const getDataByBin = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getDataByBinStart())
			const response = await api.occupationalRisks.getDataByBin(params)
			dispatch(actions.getDataByBin(response))
		} catch (error) {
			dispatch(actions.getDataByBinError(error?.response?.data?.message))
		}
	}
}

export const autosave = (payload, occupationalRisk, isSendToEmployee) => {
	return async (dispatch) => {
		try {
			// added saved api
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			// toDo need add dispatch
			message.error(err ? err : 'Неизвестная ошибка')
		}
	}
}
