import styles from './TreeTable.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HrPagination } from '@/components/Pagination'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import { PersonalAffairCard } from '@pages/PersonalAffairs/components/PersonalAffairCard/PersonalAffairCard'
import { StaffChevronDown, StaffChevronUp } from '@app/images'
import { staffingActions } from '@/entity'
import { TreeHarmful } from '@/entity/occupationalRisks/ui/components/TreeTableChild/TreeHarmful'
import { TreeEquipments } from '@/entity/occupationalRisks/ui/components/TreeTableChild/TreeEquipments'
import { TreeProtectiveEquipments } from '@/entity/occupationalRisks/ui/components/TreeTableChild/TreeProtectiveEquipments'

export const TreeTable = ({ headCells, data, getData }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)
	const totalPages = useSelector((state) => state.affairs?.affairs?.totalPages)
	const selectedAffair = useSelector(affairsSelectors.selectedAffair)
	let node = [...Array(data?.length).keys()]
	//const isOpen = useSelector((state) => state.staffing.openedChildsIds.includes(node.externalId))
	const [isOpen, setIsOpen] = useState(null)

	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)

	const handleToggleClick = (e) => {
		e.stopPropagation()
		const id = node.externalId
		if ('node.childs?.length' > 0) {
			if ('isOpen') {
				dispatch(staffingActions.removeOpenedChildId(id))
			} else {
				dispatch(staffingActions.addOpenedChildId(id))
			}
		}
	}

	const handleChangePage = (e, _page) => {
		setPage(_page - 1)
		if (_page - 1 < 0) {
			return getData('', 0, size)
		}
		getData('', _page - 1 || 0, size)
	}

	const handleChangeRowsPerPage = (_size) => {
		setSize(_size)
		if (page - 1 < 0) {
			return getData('', 0, _size)
		}
		getData('', page - 1 || 0, _size)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<>
				<tr onClick={() => setIsOpen(isOpen === index ? null : index)} key={index + '-table-row'}>
					<td
						style={{
							paddingLeft: `${2 * 10}px`,
						}}
						className={styles['td-cursor-pointer']}
					>
						{node.length > 0 ? (
							isOpen === index ? (
								<img className={styles.treeView} src={StaffChevronUp} alt="chevronUp" />
							) : (
								<img className={styles.treeView} src={StaffChevronDown} alt="chevronDown" />
							)
						) : null}
					</td>
					<td>{row.field_1 || '-'}</td>
					<td>{row.field_2 || '-'}</td>
					<td>{row.field_3 || '-'}</td>
					<td>{row.field_4 || '-'}</td>
					<td>{row.field_5 || '-'}</td>
				</tr>
				{isOpen === index && (
					<tr className={styles.addSection}>
						<td colSpan={6} style={{ width: '100%' }}>
							<TreeHarmful />
						</td>
					</tr>
				)}
				{isOpen === index && (
					<tr className={styles.addSection}>
						<td colSpan={6} style={{ width: '100%' }}>
							<TreeEquipments />
						</td>
					</tr>
				)}
				{isOpen === index && (
					<tr className={styles.addSection}>
						<td colSpan={6} style={{ width: '100%' }}>
							<TreeProtectiveEquipments />
						</td>
					</tr>
				)}
			</>
		),
		[t, isOpen]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && (
						<div className={styles.tableLoading}>{t('occupational_risks_assessment.applications.empty_app_table')}</div>
					)}
					{!isLoading && !isSuccess && <div className={styles.tableLoading}>{t('personal_affairs.has_error')}</div>}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<div className={styles.tableContainer}>
				<table className={styles.table}>
					<thead>
						<tr>{tableHeader}</tr>
					</thead>
					<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
				</table>
			</div>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 30, label: '30' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
					// { size: null, label: 'pagination.show_all' },
				]}
				rowsPerPage={size}
				numberOfElements={data.length}
				totalElements={data.length}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={page}
				className={styles.hrPaginationWrapper}
			/>
			{selectedAffair?.id && <PersonalAffairCard />}
		</>
	)
}
