import { useHistory } from 'react-router-dom'
import styles from './steps.module.scss'
import { Step } from '@/components/Step/Step'
import { useTranslation } from 'react-i18next'
import { useFormikContext, Field as FormikField } from 'formik'
import React, { useState } from 'react'
import { TreeTable } from '@/entity/occupationalRisks/ui/components'
import { tableColumnsSecondStep } from '@/entity/occupationalRisks/ui/steps/utils/tableColumnsSecondStep'
import { Button } from '@/ui'
import { RefreshGreen } from '@app/images'
import { occupationalRisksThunks } from '@/entity'
import { useDispatch, useSelector } from 'react-redux'
import { getFromLocalStorage } from '@/shared'
import { CreateStaffModal } from '@features/occupationalRisks/actions/create/CreateStaffModal'

const mockTable = [
	{ field_1: '1', field_2: '2', field_3: '3', field_4: '4', field_5: '5' },
	{ field_1: '11', field_2: '22', field_3: '33', field_4: '44', field_5: '55' },
	{ field_1: '111', field_2: '222', field_3: '333', field_4: '444', field_5: '555' },
]

export const OccupationalRisksStepTwo = () => {
	const { t } = useTranslation()
	const { setFieldValue, values, validateForm } = useFormikContext()
	const dispatch = useDispatch()
	const history = useHistory()
	const staff = useSelector((state) => state.occupationalRisks.staff)
	const activeCompany = getFromLocalStorage('activeCompany')
	const [sendStaff, setSendStaff] = useState(false)
	const stepText = 'occupational_risks_assessment.steps.step_2.'

	const onStaff = () => {
		setSendStaff(true)
		dispatch(occupationalRisksThunks.getStaff(activeCompany.bin))
	}

	return (
		<Step withoutHeight className={styles.step} notAccepted label={stepText + 'title'} index={2} opened={false}>
			<div className={styles.pTandB}>
				<div className={styles.wrapper}>
					{staff.professionGroupsDtos?.length === 0 && sendStaff && (
						<CreateStaffModal
							onProcess={() => history.push({ pathname: '/staffing', state: { tab: 1 } })}
							onCancel={() => setSendStaff(false)}
						/>
					)}
					<div className={'flex'}>
						<Button
							textColor="green"
							borderColor="green"
							transparent
							border
							buttonClassName={styles.mb2}
							icon={RefreshGreen}
							onClick={onStaff}
						>
							{t(stepText + 'update_schedule')}
						</Button>
					</div>
					<TreeTable headCells={tableColumnsSecondStep} getData={''} data={mockTable} />
				</div>
			</div>
		</Step>
	)
}
