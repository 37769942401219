import { request } from '@/shared/api/request'

export const getXml = (externalId) => {
	return request({ url: '/link' + externalId + '/xml', method: 'GET' })
}

export const getEditXml = (payload) => {
	return request({ url: 'occupationalRisk/get/xml', method: 'POST', data: payload })
}

export const deleteXml = (externalId) => {
	return request({ url: 'occupationalRisk/deletion/' + externalId + '/xml', method: 'GET' })
}

export const deleteExternalXml = (data) => {
	return request({ url: 'occupationalRisk/deletion/xml', method: 'POST', data: data })
}

export const generateRecoverXml = (payload) => {
	return request({ url: 'occupationalRisk/restore/xml', method: 'POST', data: payload })
}

export const recoverXml = (payload) => {
	return request({ url: 'occupationalRisk/sendRestorationData', method: 'POST', data: payload })
}
