import styles from './OccupationalRisksForm.module.scss'
import { Button, Field, Title } from '@/ui'
import { Formik, Field as FormikField, Form as FormikForm } from 'formik'
import { formFieldNames } from '@/entity'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IINValidationScheme } from '@/shared'
import { getFLData } from '@/shared/api/rest/contracts'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { message } from 'antd'
import { Grid } from '@mui/material'

export const OccupationalRisksForm = ({ onClose, onSubmit }) => {
	const { t } = useTranslation()
	const [isFormDirty] = useState(false)
	const [codes, setCodes] = useState([])
	const { bin } = useSelector(employerProfileSelectors.activeCompany)
	const stepText = 'occupational_risks_assessment.steps.step_5.'

	const initialValues = {
		[formFieldNames.iin]: '',
		[formFieldNames.employeeInitials]: '',
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={IINValidationScheme}
			onSubmit={(data) => onSubmit({ ...data, bin, methods: codes })}
		>
			{({ setFieldValue, values, ...form }) => {
				const getNameByIin = async () => {
					try {
						const response = await getFLData({ iin: values.iin })
						setFieldValue(formFieldNames.employeeInitials, response.fullNamePerson || '')
					} catch (error) {
						const err = error?.response?.data?.message || 'Неизвестная ошибка'
						message.error(err ? err : 'Неизвестная ошибка', 5)
					}
				}

				return (
					<FormikForm>
						<Grid container marginBottom={2} columns={8}>
							<Grid item xs={8}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_1')}
											placeholder={t(stepText + 'field_1')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
						</Grid>
						<Grid container marginBottom={2} columns={8} spacing={3}>
							<Grid item xs={6}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_2')}
											placeholder={t(stepText + 'field_2')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
							<Grid item xs={2}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_3')}
											placeholder={t(stepText + 'field_3')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
						</Grid>
						<Grid container marginBottom={2} columns={9} spacing={3}>
							<Grid item xs={3}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_4')}
											placeholder={t(stepText + 'field_4')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
							<Grid item xs={3}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_5')}
											placeholder={t(stepText + 'field_5')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
							<Grid item xs={3}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_6')}
											placeholder={t(stepText + 'field_6')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
						</Grid>
						<Grid container marginBottom={2} columns={8}>
							<Grid item xs={8}>
								<FormikField name={'preffix' + 'fact_address'}>
									{({ field, meta: { touched, error } }) => (
										<Field
											options={'genders'}
											isRequired
											label={t(stepText + 'field_7')}
											placeholder={t(stepText + 'field_7')}
											{...field}
											error={(touched || isFormDirty) && error}
										/>
									)}
								</FormikField>
							</Grid>
						</Grid>
						<Grid container justifyContent="flex-end" alignItems="center" columns={18}>
							<Grid xs={2}>
								<Button type="bare" onClick={onClose}>
									{t('cancel')}
								</Button>
							</Grid>
							<Grid xs={2}>
								<Button onClick={() => ''}>{t('save')}</Button>
							</Grid>
						</Grid>
					</FormikForm>
				)
			}}
		</Formik>
	)
}
