import { Button } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components/index'
import { Search } from '@pages/PersonalAffairs/components/Search/Search'
import styles from './OccupationalRisksAssessment.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import { tableColumns } from '@/entity/occupationalRisks/ui/components/Table/tableColumns'
import { useHistory } from 'react-router-dom'
import { equipmentThunks, occupationalRisksThunks, protectiveEquipmentThunks } from '@/entity'
import { getFromLocalStorage } from '@/shared'
import OccupationalRisksSearch from '@features/occupationalRisks/search/occupationalRisksSearch'

const OccupationalRisksContent = () => {
	const { t } = useTranslation()
	const baseText = 'occupational_risks_assessment.applications.'
	const dispatch = useDispatch()
	const history = useHistory()
	const { content: affairs, totalElements } = useSelector(affairsSelectors.employerAffairs)
	const activeCompany = getFromLocalStorage('activeCompany')

	const getData = useCallback((srcText = '', page = 0, size = 10) => {
		//dispatch(occupationalRisksThunks.getStaff(activeCompany.bin))
	})

	useEffect(() => {
		getData()
	}, [dispatch])

	return (
		<div className={styles.wrapper}>
			<div className={'flex'}>
				<Button
					buttonClassName={styles.mb2}
					icon={addWhiteIcon}
					onClick={() => history.push(`/applications-ora/${Math.random()}`)}
				>
					{t(baseText + 'create_new_app')}
				</Button>
			</div>
			<OccupationalRisksSearch filters={''} setFilters={''} fetchData={''} resetFilter={''} page={''} pageSize={''} />
			<Table headCells={tableColumns} getData={getData} data={[]} />
		</div>
	)
}
export default OccupationalRisksContent
