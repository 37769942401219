import { transformStringDateFormatToDate } from '@/shared/lib/helpers/transformStringDateFormatToDate.js'
import { countriesIds, formates, workingTimeMode } from '@app/config'
import { format, isAfter, subYears } from 'date-fns'
import { addMethod, date, object, string } from 'yup'

const validityTermIdsWhenEndContractDateIsRequired = [2, 3, 6, 9]
const citiesInRegionsCodes = ['750000000', '710000000', '790000000']

addMethod(string, 'checkCountryRemoteWork', function () {
	return this.when(['dremoteWorkCode', 'dcountryCode'], {
		is: (remoteWork, country) => remoteWork !== '1' && +country === countriesIds.kz,
		then: (schema) => schema.required({ key: 'required' }),
	})
})

addMethod(date, 'minDateBefore', function (params) {
	const { error, yearsBefore } = params
	const currentDate = new Date()
	const dateBefore = subYears(currentDate, yearsBefore)
	return this.test('minDateBefore', error, function (value) {
		const { path, createError } = this
		return isAfter(dateBefore, value)
			? createError({ path, message: { key: error, value: format(dateBefore, formates.date), minDate: dateBefore } })
			: true
	})
})

const FormValidationSchema = object().shape({
	fullName: string().required({ key: 'required' }).nullable(),
})
export const ValidationSchemaContractORA = FormValidationSchema
