import styles from './EquipmentForm.module.scss'
import { Button, Field } from '@/ui'
import { Field as FormikField, connect } from 'formik'
import { formFieldNames } from '@/entity'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Grid } from '@mui/material'

export const EquipmentForm = connect(
	({ formik: { setFieldValue, values, errors }, name, index, showIndex, onClose, remove, isFormDirty }) => {
		const { t } = useTranslation()
		const [codes, setCodes] = useState([])
		const { bin } = useSelector(employerProfileSelectors.activeCompany)
		const baseText = 'occupational_risks_assessment.regulatory_information.equipment_modal.'
		let preffix = name + '.' + index + '.'

		return (
			<>
				<h5>{t(baseText + 'object') + (showIndex ? ' ' + (index + 1) : '')} </h5>
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={8}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'name'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t(baseText + 'field_1')}
									placeholder={t(baseText + 'field_1')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid alignItems="flex-start" container marginBottom={2} spacing={4} columns={16}>
					<Grid item xs={5}>
						<FormikField name={preffix + 'commissioningDate'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="datePicker"
									isRequired
									label={t(baseText + 'field_2')}
									placeholder={t(baseText + 'field_2')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={5}>
						<FormikField name={preffix + 'serviceLife'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									disabled={values?.dicts[index]?.isIndefinitely}
									isRequired={!values?.dicts[index]?.isIndefinitely}
									label={t(baseText + 'field_3')}
									placeholder={t(baseText + 'field_3')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={preffix + 'isIndefinitely'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="checkbox"
									firstField
									wrapperClassName={styles.rowCheckbox}
									label={t(baseText + 'field_4')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} spacing={4} columns={15}>
					<Grid item xs={5}>
						<FormikField name={preffix + 'isWithinServiceLife'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									isBoolean
									value={false}
									firstId={preffix + 'isWithinServiceLife_1'}
									secondId={preffix + 'isWithinServiceLife_2'}
									isRequired
									label={t(baseText + 'field_5')}
									placeholder={t(baseText + 'field_5')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>

				{showIndex && (
					<Button buttonClassName={'float-end'} type="bare" onClick={remove.bind(null, index)}>
						{t('remove')}
					</Button>
				)}
			</>
		)
	}
)
