import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NewOccupationalRisksAssessmentForm } from '@/entity/newOccupationalRisks/ui/form/NewOccupationalRisksAssessmentForm'
import { ValidationSchemaOccupationalRisks } from '@/entity/newOccupationalRisks/lib/schemas'
import { initialOccupationalRisks } from '@/entity/occupationalRisks/model/reducer'

export const NewOccupationalRisksAssessment = () => {
	const { t } = useTranslation()
	const onSubmit = (data) => {
		console.log(data)
	}
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_occupational_risks_assessment')}>
				<Formik
					initialValues={initialOccupationalRisks}
					validationSchema={ValidationSchemaOccupationalRisks}
					onSubmit={onSubmit}
				>
					<NewOccupationalRisksAssessmentForm />
				</Formik>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
