import classes from './Search.module.scss'
import InputBase from '@mui/material/InputBase'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userSelectors } from '@/entity'
import { Button } from '@/ui'

export const Search = ({ fetchData, count = 0, isRequests = false }) => {
	const { t } = useTranslation()
	const [searchValue, setSearchValue] = useState('')

	const onChange = (e) => setSearchValue(e.target.value)
	const onEnterKeyPressed = (e) => e.key === 'Enter' && e.preventDefault() && fetchData()

	const activeRole = useSelector(userSelectors.activeRole)

	return (
		<div className={classes.search}>
			<div className={classes.search__field}>
				<InputBase
					fullWidth
					placeholder={t('search')}
					inputProps={{ 'aria-label': 'search' }}
					value={searchValue}
					onChange={onChange}
					onKeyDown={onEnterKeyPressed}
				/>
				<Button className={classes.search__button} onClick={() => fetchData({ srcText: searchValue })}>
					{t('find')}
				</Button>
			</div>
		</div>
	)
}
