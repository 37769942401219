import TabPanel from '@mui/base/TabPanel'
import * as React from 'react'
import TabsUnstyled from '@mui/base/Tabs'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import { Tags } from '@/features'
import { styled } from '@mui/system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import styles from './TreeTableChild.module.scss'
import { TreeCheckbox } from '@/components/TreeCheckbox/TreeCheckbox'
import { Button, Title } from '@/ui'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Add } from '@app/icons'
import { useDispatch, useSelector } from 'react-redux'
import { occupationalRisksThunks, userSelectors } from '@/entity'
import { getCurrentLanguage } from '@/i18next'
const Tabs = styled(TabsUnstyled)({})

export function TreeHarmful() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [treeData, setTreeData] = useState()
	const harmfulData = useSelector((state) => state.occupationalRisks.harmful)
	const currentLang = getCurrentLanguage()

	useEffect(() => {
		dispatch(occupationalRisksThunks.getHarmful())
	}, [])

	const handleTreeChange = (updatedData) => {
		setTreeData(updatedData)
	}
	const [activeTab, setActiveTab] = useState(true)
	const [openDicts, setOpenDicts] = useState(false)
	const handleChangeTab = (e, newValue) => setActiveTab(newValue)
	return (
		<div>
			{openDicts && (
				<Modal windowClassName={styles.modal}>
					<ModalBackButton onClick={() => setOpenDicts(false)} />
					<div className={`${styles.wrapper} scroll`}>
						<div className={styles.header}>
							<Title className={styles.title}>Вредные факторы</Title>
						</div>
						<Grid paddingX={2}>
							<Tabs defaultValue={0}>
								<TabsList>
									{harmfulData &&
										harmfulData?.map((h, i) => (
											<Tab key={h.code + i} value={i}>
												{currentLang !== 'rus' ? h.nameKz : h.nameRu}
											</Tab>
										))}
								</TabsList>
								{harmfulData &&
									harmfulData?.map((h, i) => (
										<TabPanel value={i}>
											<TreeCheckbox className={styles.treeCheckbox} data={h.child} onChange={handleTreeChange} />
										</TabPanel>
									))}
							</Tabs>
						</Grid>
					</div>
				</Modal>
			)}
			<Grid
				paddingX={2}
				paddingY={1}
				className={styles.addButton}
				container
				columns={12}
				justifyContent="space-between"
			>
				<Grid item xs={2}>
					{t('occupational_risks_assessment.steps.step_2.harmful')}
				</Grid>
				<Grid textAlign="end" item xs={2}>
					<Button onClick={() => setOpenDicts(!openDicts)} transparent buttonClassName={styles.button}>
						<Add className={styles.iconGreen} />
						{t('add')}
					</Button>
				</Grid>
			</Grid>
			<Grid paddingX={2}>
				<Tabs className={styles.treeTabs} defaultValue={0}>
					<TabsList>
						<Tab className={styles.tabItem} value={0}>
							физические
						</Tab>
					</TabsList>
					<TabPanel value={0}>
						<Tags onlyTags tags={[{ nameRu: 'test RU' }, { nameRu: 'test RU2' }] ?? []} />
					</TabPanel>
				</Tabs>
			</Grid>
		</div>
	)
}
