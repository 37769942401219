import styles from './steps.module.scss'
import { Step } from '@/components/Step/Step'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { InfoMedium } from '@app/icons'
import React from 'react'

export const OccupationalRisksStepFour = () => {
	const { t } = useTranslation()
	const { setFieldValue, values, validateForm } = useFormikContext()
	const stepText = 'occupational_risks_assessment.steps.step_4.'

	return (
		<Step
			classNameContent={styles.pTandB}
			withoutHeight
			className={styles.step}
			notAccepted
			label={stepText + 'title'}
			index={4}
			opened={false}
		>
			<div className={styles.pTandB}>
				<div className={styles.information}>
					<InfoMedium className={styles.iconGreen} />
					<div className={styles.labelError}>{t(stepText + 'info')}</div>
				</div>

				{/*{values.membersOfCommissionORA.map((index) => (
				<ListOfEquipment
					key={index}
					//isFormDirty={isFormDirty}
					name={'membersOfCommissionORA'}
					index={index}
					showIndex={values.membersOfCommissionORA.length > 1}
				/>
			))}
			<Button
				textColor="green"
				borderColor="green"
				transparent
				border
				type="button"
				icon={Plus}
				onClick={() =>
					setFieldValue('membersOfCommissionORA', [...values.membersOfCommissionORA, { isResident: true }])
				}
			>
				{t('occupational_risks_assessment.add_equip')}
			</Button>
			<Grid container justifyContent="flex-end">
				<Button type="button">{t('save')}</Button>
			</Grid>*/}
			</div>
		</Step>
	)
}
