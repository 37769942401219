import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styles from './Dropzone.module.scss'
import { Button, Icon } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Download } from '@app/images'
import { Trash } from '@app/icons'

const DropzoneFile = ({
	format = ['csv', 'xls', 'xlsx'],
	uploadedFile,
	setUploadedFile,
	errorMessage,
	setErrorMessage,
}) => {
	const { t } = useTranslation()

	// Допустимые форматы файлов
	const allowedFormats = [
		'text/csv',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	]

	const onDrop = (acceptedFiles, fileRejections) => {
		if (fileRejections.length > 0) {
			setErrorMessage('Неподдерживаемый формат файла. Попробуйте csv, xls или xlsx.')
			return
		}

		if (acceptedFiles.length > 0) {
			const file = acceptedFiles[0]
			setUploadedFile(file)
			setErrorMessage('')
			console.log('Файл загружен:', file)
		}
	}

	const handleRemove = () => {
		setUploadedFile(null)
		console.log('Файл удален')
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: allowedFormats.join(','),
		maxFiles: 1, // Ограничение: 1 файл
		onDropRejected: (rejections) => {
			setErrorMessage('Неподдерживаемый формат файла. Попробуйте csv, xls или xlsx.')
		},
	})

	return (
		<div className={styles.dropzoneContainer}>
			{!uploadedFile ? (
				<div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.active : ''}`}>
					<Icon src={Download} classes={styles.icon} />
					<div>
						<input {...getInputProps()} />
						<p>{t('dropzone_info')}</p>
						<span className={styles.formats}>
							{t('dropzone_format')}: {format.join(', ')}
						</span>
					</div>
					<Button transparent border buttonClassName={styles.chooseFileBtn}>
						{t('choose_file')}
					</Button>
				</div>
			) : (
				<div className={styles.filePreview}>
					<span className={styles.fileInfo}>
						{uploadedFile.name} ({(uploadedFile.size / 1024 / 1024).toFixed(1)} MB)
					</span>
					<Button transparent buttonClassName={styles.removeBtn} onClick={handleRemove}>
						<Trash className={styles.iconRed} />
						{t('remove')}
					</Button>
				</div>
			)}

			{errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
		</div>
	)
}

export default DropzoneFile
