import styles from './OccupationalRisksForm.module.scss'
import { Button, Field, Title } from '@/ui'
import { Formik, Field as FormikField, Form as FormikForm } from 'formik'
import { formFieldNames, occupationalRisksThunks } from '@/entity'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IINValidationScheme } from '@/shared'
import { WrappedCheckbox } from '@features/contracts/registrationEmployerContract/form/fields'
import { getFLData } from '@/shared/api/rest/contracts'
import { useDispatch, useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { message } from 'antd'

export const OccupationalRisksForm = ({ onClose, onSubmit }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [isFormDirty] = useState(false)
	const [codes, setCodes] = useState([])
	const { bin } = useSelector(employerProfileSelectors.activeCompany)
	const branches = useSelector((state) => state.occupationalRisks.branches)

	useEffect(() => {
		dispatch(occupationalRisksThunks.getBranches())
	}, [])

	const initialValues = {
		[formFieldNames.iin]: '',
		[formFieldNames.employeeInitials]: '',
	}

	const submit = (data) => console.log(codes)

	const selectAll = () =>
		setCodes((prev) =>
			prev.length === 10
				? []
				: [
						'001',
						'002',
						'004',
						'005',
						'006',
						'007',
						'009',
						'010',
						'014',
						'017',
						// todo раскомментить 31.10.2024 branch: reviews
						// '018'
				  ]
		)
	const toggleCheckbox = (code) =>
		setCodes((prev) => (prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code]))

	return (
		<>
			<section>
				<div className={styles.col}>
					{branches.length > 1 && (
						<WrappedCheckbox onChange={selectAll} value={codes.length === 10} label={t('select_all')} />
					)}
					{branches.map((item, index) => (
						<WrappedCheckbox
							key={item.externalId}
							onChange={() => toggleCheckbox('001')}
							value={codes.includes('001')}
							label={t('personal_affairs.request.form.id_label')}
						/>
					))}
				</div>
			</section>
			<div className={styles.buttonsRow}>
				<Button type="bare" onClick={onClose}>
					{t('cancel')}
				</Button>
				<Button disabled={codes.length === 0} type="submit" onClick={submit}>
					{t('save')}
				</Button>
			</div>
		</>
	)
}
