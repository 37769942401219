import React, { useCallback, useEffect, useState } from 'react'
import styles from './EmployeesInNeed.module.scss'
import { ConfirmModal, Title } from '@/ui'
import { useTranslation } from 'react-i18next'
import { getAidXml } from '@/shared/api/rest/personalAffairs/getAidXml'
import { useDigitalSign } from '@/shared'
import { Buffer } from 'buffer'
import { message } from 'antd'
import { sendAidXml } from '@/shared/api/rest/personalAffairs/sendAidXml'
import { LoadingBlocker } from '@/components/Loader'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
const EmployeesInNeedModal = ({ onCancel }) => {
	const {
		t,
		i18n: { language },
	} = useTranslation()
	const activeCompany = useSelector(employerProfileSelectors.activeCompany)
	const { successPayload, onClearPayload, isLoading, failedPayload, onSign } = useDigitalSign()
	const [loading, setLoading] = useState(false)
	const getXml = useCallback(async () => {
		try {
			onSign(await getAidXml(activeCompany.bin))
		} catch (error) {
			console.log('dd')
		}
	}, [])
	const sendXml = async () => {
		setLoading(true)
		try {
			await sendAidXml({
				bin: activeCompany.bin,
				xml: Buffer.from(successPayload).toString('base64'),
			})
			message.success('Операция была успешна выполнена!')
			onCancel()
		} catch (error) {
			message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			setLoading(false)
		}
	}
	useEffect(() => {
		successPayload && sendXml()
		return () => onClearPayload()
	}, [successPayload])
	return (
		<>
			<ConfirmModal
				onProcess={getXml}
				onCancel={onCancel}
				processButtonTitle={'welfare.tabs.model.button'}
				cancelButtonTitle={'cancel'}
				hideBackButton
				priority={2}
			>
				<div className={styles.content}>
					{language === 'rus' ? (
						<>
							<h1 className={styles.title}>Запрос согласия работников</h1>
							<p>
								Работникам, вошедшим в категории Кризисная, Экстренная будет отправлен запрос согласия на сбор и
								обработку персональных данных (СМС-сообщение). Работник должен быть зарегистрирован в Базе мобильных
								граждан{' '}
								<a
									className={styles.greenLink}
									target="_blank"
									href={'https://egov.kz/cms/ru/articles/mobilecitizen'}
									rel="noreferrer"
								>
									{' '}
									(инструкция по регистрации в БМГ)
								</a>
								. После подтверждения работника, он(она) появится в списке. Работникам, которые уже предоставили
								согласие, СМС отправляться не будет.
							</p>
						</>
					) : (
						<>
							<h1 className={styles.title}>Қызметкерлердің келісімін сұрау</h1>
							<p>
								Дағдарысты, шұғыл санатқа кірген қызметкерлерге дербес деректерді жинауға және өңдеуге келісім сұрау
								(СМС-хабарлама) жіберілетін болады. Ол үшін қызметкер мобильді азаматтар базасында тіркелуі керек
								<a
									className={styles.greenLink}
									target="_blank"
									href={'https://egov.kz/cms/kk/articles/mobilecitizen'}
									rel="noreferrer"
								>
									{' '}
									(МАБ-да тіркеу жөніндегі нұсқаулық)
								</a>
								.Тізімде тек келісім берген жұмысшылар көрсетіледі.
							</p>
						</>
					)}
				</div>
			</ConfirmModal>
			{(isLoading || loading) && <LoadingBlocker localStyles={{ zIndex: '1003' }} />}
		</>
	)
}

export default EmployeesInNeedModal
