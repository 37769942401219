import styles from './steps.module.scss'
import { Step } from '@/components/Step/Step'
import { Button, Field } from '@/ui'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Field as FormikField } from 'formik'
import { InfoMedium } from '@app/icons'

export const OccupationalRisksStepThree = () => {
	const { t } = useTranslation()
	const [codes, setCodes] = useState([])
	const stepText = 'occupational_risks_assessment.steps.step_3.'

	const toggleCheckbox = (code) =>
		setCodes((prev) => (prev.includes(code) ? prev.filter((item) => item !== code) : [...prev, code]))
	return (
		<Step withoutHeight className={styles.step} notAccepted label={stepText + 'title'} index={3} opened={false}>
			<div className={styles.pTandB}>
				<Grid marginBottom={2} alignItems="flex-end" container spacing={4} columns={16}>
					<Grid item xs={6}>
						<FormikField name={'object'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={'genders'}
									isRequired
									label={t(stepText + 'field_1')}
									placeholder={t(stepText + 'field_1')}
									{...field}
									error={(touched || 'isFormDirty') && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={6}>
						<FormikField name={'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={'genders'}
									isRequired
									label={t(stepText + 'field_2')}
									placeholder={t(stepText + 'field_2')}
									{...field}
									error={(touched || 'isFormDirty') && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<div className={styles.information}>
					<InfoMedium className={styles.iconRed} />
					<div className={styles.someInfo}>
						<div className={styles.labelError}>{t(stepText + 'info.first_row')}</div>
						<div className={styles.labelError}>{t(stepText + 'info.second_row')}</div>
						<div className={styles.labelError}>{t(stepText + 'info.third_row')}</div>
						<div className={styles.labelError}>{t(stepText + 'info.fourth_row')}</div>
						<div className={styles.labelError}>{t(stepText + 'info.fifth_row')}</div>
					</div>
				</div>
				<Button onClick={() => ''} disabled={''}>
					{t('sign_and_send')}
				</Button>
			</div>
		</Step>
	)
}
