import styles from './ProtectiveEquipmentForm.module.scss'
import { Button, Field, Title } from '@/ui'
import { Formik, Field as FormikField, Form as FormikForm, connect } from 'formik'
import { formFieldNames } from '@/entity'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { employerProfileSelectors } from '@features/employerProfile/model'
import { Grid } from '@mui/material'

export const ProtectiveEquipmentForm = connect(
	({ formik: { setFieldValue, values, errors }, name, index, showIndex, onClose, remove, isFormDirty }) => {
		const { t } = useTranslation()
		const { bin } = useSelector(employerProfileSelectors.activeCompany)
		const baseText = 'occupational_risks_assessment.regulatory_information.protective_equipment_modal.'
		let preffix = name + '.' + index + '.'

		return (
			<>
				<h5>{t(baseText + 'object') + (showIndex ? ' ' + (index + 1) : '')} </h5>
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={8}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'nameKz'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t(baseText + 'field_1')}
									placeholder={t(baseText + 'field_1')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={8}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'nameRu'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t(baseText + 'field_2')}
									placeholder={t(baseText + 'field_2')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={8}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'normativDocument'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t(baseText + 'field_3')}
									placeholder={t(baseText + 'field_3')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} marginTop={1} spacing={4} columns={9}>
					<Grid item xs={3}>
						<FormikField name={preffix + 'issueRateInMonths'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									isRequired
									label={t(baseText + 'field_4')}
									placeholder={t(baseText + 'field_4')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				<Grid container marginBottom={2} spacing={3} columns={9}>
					<Grid item xs={3}>
						<FormikField name={preffix + 'isCompliantWithStandards'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									isBoolean
									value={values[preffix + 'isCompliantWithStandards']}
									firstId={preffix + '1'}
									secondId={preffix + '2'}
									isRequired
									label={t(baseText + 'field_5')}
									placeholder={t(baseText + 'field_5')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={6}>
						<FormikField name={preffix + 'isCert'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									fieldType="twoRadio"
									isBoolean
									value={values[preffix + 'isCert']}
									firstId={preffix + 'isCert_3'}
									secondId={preffix + 'isCert_4'}
									isRequired
									label={t(baseText + 'field_6')}
									placeholder={t(baseText + 'field_6')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>

				{showIndex && (
					<Button buttonClassName={'float-end'} type="bare" onClick={remove.bind(null, index)}>
						{t('remove')}
					</Button>
				)}
			</>
		)
	}
)
