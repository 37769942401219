import { Button, Checkbox } from '@/ui'
import addWhiteIcon from 'assets/pages/personalAffairs/addWhite.svg'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from './components/index'
import styles from './RegulatoryInformationTab.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { equipmentThunks, protectiveEquipmentThunks } from '@/entity/regulatoryInformation'
import CheckingSpecialSocialPaymentModal from '@pages/PersonalAffairs/Tabs/PersonalAffairsMainTab/components/CheckingSpecialSocialPaymentModal/CheckingSpecialSocialPaymentModal'
import { Download } from '@app/images'
import {
	tableColumns,
	tableColumnsFull,
	tableColumnsProtective,
	tableColumnsProtectiveFull,
} from '@/entity/regulatoryInformation/ui/tabs/components/Table/tableColumns'
import classNames from 'classnames'
import { EquipmentModal } from '@/entity/regulatoryInformation/ui/tabs/components/EquipmentModal/EquipmentModal'
import { ProtectiveEquipmentModal } from '@/entity/regulatoryInformation/ui/tabs/components/ProtectiveEquipmentModal/ProtectiveEquipmentModal'
import { getFromLocalStorage, setToLocalStorage } from '@/shared'
import { ConfirmRegulatoryModal } from '@features/regulatoryInformation/actions/delete/ConfirmRegulatoryModal'
import { Search } from '@/entity/regulatoryInformation/ui/tabs/components/Search/Search'
import { ImportModal } from '@/entity/regulatoryInformation/ui/tabs/components/ImportModal/ImportModal'

const RegulatoryInformationTab = ({ equipment }) => {
	const { t } = useTranslation()
	const baseText = 'occupational_risks_assessment.regulatory_information.'
	const dispatch = useDispatch()
	const [isRequestModalOpen, setRequestModalOpen] = useState({ create: false, edit: false })
	const [isOpenModalRight, setOpenModalRight] = useState(false)
	const [removeElement, setRemoveElement] = useState({ dicts: [] })
	const tableShortness = getFromLocalStorage('changeTableColumns')
	const isDeletedReg = getFromLocalStorage('regulatoryIsDeleted')
	const [changeCheckbox, setChangeCheckbox] = useState(tableShortness)
	const [isOpenImport, setIsOpenImport] = useState(false)
	const [isDeleted, setIsDeleted] = useState(isDeletedReg)
	const equipmentData = useSelector((state) => state.equipment.equipments)
	const protectiveEquipmentData = useSelector((state) => state.protectiveEquipment.equipments)
	const columnsEq = tableShortness ? tableColumns : tableColumnsFull
	const columnsProtective = tableShortness ? tableColumnsProtective : tableColumnsProtectiveFull

	const getData = useCallback(
		(srcText = '', page = 0, size = 10) => {
			const params = {
				...srcText,
				isDeleted,
			}
			equipment
				? dispatch(equipmentThunks.getEquipments(params, page, size))
				: dispatch(protectiveEquipmentThunks.getProtectiveEquipments(params, page, size))
		},
		[equipment, isDeleted, dispatch]
	)

	const changeTableColumns = (checked) => {
		setChangeCheckbox(!changeCheckbox)
		setToLocalStorage('changeTableColumns', checked)
	}

	const handleShowRegulatoryIsDeleted = (checked) => {
		setIsDeleted(!isDeleted)
		setToLocalStorage('regulatoryIsDeleted', checked)
	}

	const onActionMenuChoose = (mode, row) => {
		if (mode === 'remove') {
			setRemoveElement({ dicts: [row] })
		} else {
			setRequestModalOpen({ edit: true, create: false })
			dispatch(equipmentThunks.getEquipment(row))
			dispatch(protectiveEquipmentThunks.getProtectiveEquipment(row))
		}
	}

	const handleRemove = () => {
		equipment
			? dispatch(equipmentThunks.remove(removeElement))
			: dispatch(protectiveEquipmentThunks.remove(removeElement))
		setRemoveElement({ dicts: [] })
	}

	const downloadFile = () => {
		equipment ? dispatch(equipmentThunks.downloadFile()) : dispatch(protectiveEquipmentThunks.downloadFile())
	}

	useEffect(() => getData(), [dispatch, isDeleted])

	return (
		<div className={styles.wrapper}>
			{removeElement.dicts.length > 0 && (
				<ConfirmRegulatoryModal onProcess={handleRemove} onCancel={() => setRemoveElement({ dicts: [] })} />
			)}
			{isOpenImport && <ImportModal equipment onClose={() => setIsOpenImport(!isOpenImport)} />}
			{(isRequestModalOpen.edit || isRequestModalOpen.create) && equipment && (
				<EquipmentModal
					isEdit={isRequestModalOpen.edit}
					onClose={() => setRequestModalOpen({ edit: false, create: false })}
				/>
			)}
			{(isRequestModalOpen.edit || isRequestModalOpen.create) && !equipment && (
				<ProtectiveEquipmentModal
					isEdit={isRequestModalOpen.edit}
					onClose={() => setRequestModalOpen({ edit: false, create: false })}
				/>
			)}
			{isOpenModalRight ? <CheckingSpecialSocialPaymentModal onClose={() => setOpenModalRight(false)} /> : null}
			<Search count={0} fetchData={getData} />
			<div className={classNames('flex', styles.aiC, styles.mT)}>
				<Button
					buttonClassName={styles.wrapper__button}
					icon={addWhiteIcon}
					onClick={() => {
						setRequestModalOpen({ edit: false, create: true })
						dispatch(equipmentThunks.getEquipment({}))
					}}
				>
					{t(baseText + 'add_equip')}
				</Button>
				<Button
					buttonClassName={styles.wrapper__button}
					textColor="green"
					borderColor="green"
					transparent
					border
					type="button"
					icon={Download}
					onClick={() => setIsOpenImport(!isOpenImport)}
				>
					{t(baseText + 'import')}
				</Button>
				<Button
					buttonClassName={styles.wrapper__button}
					textColor="green"
					borderColor="green"
					transparent
					border
					type="button"
					icon={Download}
					onClick={downloadFile}
				>
					{t(baseText + 'download_dict')}
				</Button>
				<Checkbox
					value={isDeleted}
					className={styles.wrapper__button}
					label={t(baseText + 'show_remove')}
					onChange={handleShowRegulatoryIsDeleted}
				/>
				<Checkbox value={changeCheckbox} label={t(baseText + 'abbreviated')} onChange={changeTableColumns} />
			</div>
			<Table
				equipment={equipment}
				onActionMenuChoose={onActionMenuChoose}
				headCells={equipment ? columnsEq : columnsProtective}
				getData={getData}
				data={equipment ? equipmentData : protectiveEquipmentData}
			/>
		</div>
	)
}
export default RegulatoryInformationTab
