import styles from './Table.module.scss'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HrPagination } from '@/components/Pagination'
import { LoadingScreen } from '@/components/Loader'
import { Title } from '@/ui'
import { useDispatch, useSelector } from 'react-redux'
import { affairsSelectors, affairsThunks } from '@/entity/personalAffairs'
import { PersonalAffairCard } from '@pages/PersonalAffairs/components/PersonalAffairCard/PersonalAffairCard'
import DropdownMenu from '@/components/DropdownMenu/DropdownMenu'
import { Edit as EditIcon, Remove as RemoveIcon } from '@app/images'
import { getFromLocalStorage } from '@/shared'

export const Table = ({ headCells, data, getData, onActionMenuChoose, equipment }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const tableShortness = getFromLocalStorage('changeTableColumns')
	const isLoading = useSelector(affairsSelectors.isLoading)
	const isSuccess = useSelector(affairsSelectors.isSuccess)
	const totalPagesProtective = useSelector((state) => state.protectiveEquipment.totalPages)
	const totalPagesEquipments = useSelector((state) => state.equipment.totalPages)
	const selectedAffair = useSelector(affairsSelectors.selectedAffair)
	const totalPages = equipment ? totalPagesEquipments : totalPagesProtective

	const [page, setPage] = useState(0)
	const [size, setSize] = useState(10)

	const getAffairInfo = (id) => id && dispatch(affairsThunks.getAffairForEmployer(id))

	const handleChangePage = (e, _page) => {
		setPage(_page - 1)
		if (_page - 1 < 0) {
			return getData('', 0, size)
		}
		getData('', _page - 1 || 0, size)
	}

	const handleChangeRowsPerPage = (_size) => {
		setSize(_size)
		if (page - 1 < 0) {
			return getData('', 0, _size)
		}
		getData('', page - 1 || 0, _size)
	}

	const getTableRow = useCallback(
		(row, index) => (
			<tr key={index + '-table-row'}>
				{headCells.map((cell, id) =>
					cell.id !== 'actionRow' ? (
						<td key={id + cell.id} style={{ width: cell.width }}>
							{cell.id === 'status'
								? t(`occupational_risks_assessment.regulatory_information.${row[cell.id]}`)
								: row[cell.id] === true
								? t('yes')
								: row[cell.id] === false
								? t('no')
								: row[cell.id] || '-'}
						</td>
					) : (
						<td key={id + cell.id} onClick={() => getAffairInfo(row.id)} style={{ width: cell.width }}>
							<DropdownMenu
								threeDots
								onItemClick={(mode) => onActionMenuChoose(mode, row)}
								items={[
									{ icon: EditIcon, title: t('actions.edit'), action: 'edit' },
									{ icon: RemoveIcon, title: t('actions.remove'), action: 'remove' },
								]}
								title={t('select_pls')}
							/>
						</td>
					)
				)}
			</tr>
		),
		[t, headCells]
	)

	const tableBody = useMemo(() => data.map((row, index) => getTableRow(row, index)), [data, getTableRow])

	const tableHeader = useMemo(
		() => (
			<>
				{headCells.map((head, index) => (
					<th key={index + 'thead-th'}>{t(head.label)}</th>
				))}
			</>
		),
		[t, headCells]
	)

	const tableLoading = useMemo(
		() => (
			<tr>
				<td colSpan={11} className={styles.messageCol}>
					{isLoading && (
						<LoadingScreen>
							<Title>{t('wait')}</Title>
							<Title>{t('loading_from_esutd')}</Title>
						</LoadingScreen>
					)}
					{!isLoading && isSuccess && !data?.length && <div className={styles.tableLoading}>Нужен текст</div>}
					{!isLoading && !isSuccess && (
						<div className={styles.tableLoading}>
							{t('occupational_risks_assessment.regulatory_information.has_error')}
						</div>
					)}
				</td>
			</tr>
		),
		[isLoading, isSuccess, data, t]
	)

	return (
		<>
			<div className={tableShortness ? '' : styles.wFull}>
				<table className={styles.table}>
					<thead>
						<tr>{tableHeader}</tr>
					</thead>
					<tbody>{!!data?.length ? tableBody : tableLoading}</tbody>
				</table>
			</div>
			<HrPagination
				id="hr-pagination"
				rowsPerPageitems={[
					{ size: 10, label: '10' },
					{ size: 30, label: '30' },
					{ size: 50, label: '50' },
					{ size: 100, label: '100' },
					// { size: null, label: 'pagination.show_all' },
				]}
				rowsPerPage={size}
				numberOfElements={data.length}
				totalElements={data.length}
				totalPages={totalPages}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				page={page}
			/>
			{selectedAffair?.id && <PersonalAffairCard />}
		</>
	)
}
