import { useState } from 'react'
import { getCurrentLanguage } from '@/i18next'

export const TreeCheckbox = ({ data, onChange, className }) => {
	const currentLang = getCurrentLanguage()
	const [expandedNodes, setExpandedNodes] = useState({}) // Track expanded nodes

	// Function to toggle expanded state
	const toggleExpand = (id) => {
		setExpandedNodes((prev) => ({
			...prev,
			[id]: !prev[id],
		}))
	}

	// Recursive function to render tree items
	const renderTree = (nodes, level = 0) => {
		return nodes.map((node) => (
			<div key={node.code} style={{ marginLeft: level * 20 }}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{node.child && node.child?.length > 0 && (
						<span style={{ cursor: 'pointer', marginRight: 5 }} onClick={() => toggleExpand(node.code)}>
							{expandedNodes[node.code] ? '−' : '+'}
						</span>
					)}
					<input type="checkbox" id={node.code} checked={node.checked} onChange={() => handleCheckboxChange(node)} />
					<label htmlFor={node.code}>{currentLang === 'rus' ? node.nameRu : node.nameKz}</label>
				</div>
				{node.child && expandedNodes[node.code] && renderTree(node.child, level + 1)}
			</div>
		))
	}

	const handleCheckboxChange = (node) => {
		const updatedData = updateNode(data, node.code, !node.checked)
		onChange(updatedData) // Notify parent component about data change
	}

	const updateNode = (nodes, id, checked) => {
		return nodes.map((node) => {
			if (node.code === id) {
				return {
					...node,
					checked,
					children: node.child ? propagateCheckedState(node.child, checked) : [],
				}
			}

			if (node.child) {
				return {
					...node,
					children: updateNode(node.child, id, checked),
				}
			}

			return node
		})
	}

	const propagateCheckedState = (nodes, checked) => {
		return nodes.map((node) => ({
			...node,
			checked,
			children: node.child ? propagateCheckedState(node.child, checked) : [],
		}))
	}

	return <div className={className}>{renderTree(data)}</div>
}
