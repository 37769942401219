import { useTranslation } from 'react-i18next'
import { ConfirmModal, Title } from '@/ui'
import styles from './QuestionModal.module.scss'

export const QuestionModal = ({ handleProcess, onCancel }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={handleProcess}
			onCancel={onCancel}
			processButtonTitle={'forms.model.process_button_title'}
			cancelButtonTitle={'forms.model.cancel'}
			hideBackButton
			priority={2}
		>
			<div className={styles.content}>
				<Title className={styles.content__title}>{t('forms.model.title')}</Title>
				<p>{t('forms.model.bodyPart_1')}</p>
				<p>{t('forms.model.bodyPart_2')}</p>
			</div>
		</ConfirmModal>
	)
}
