export const tableColumnsSecondStep = [
	{
		id: 'id',
		numeric: false,
		label: '',
	},
	{
		id: 'number',
		numeric: false,
		label: 'occupational_risks_assessment.steps.step_2.step_2_table.field_1',
	},
	{
		id: 'object',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.step_2_table.field_2',
	},
	{
		id: 'date_app',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.step_2_table.field_3',
	},
	{
		id: 'planned_dates',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.step_2_table.field_4',
	},
	{
		id: 'total_cost',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.step_2_table.field_5',
	},
]
