import * as types from './types'

export const initialProtectiveEquipment = {
	dicts: [
		{
			isCert: false,
			isCompliantWithStandards: false,
			issueRateInMonths: null,
			nameKz: '',
			nameRu: '',
			normativDocument: '',
		},
	],
}

const initialParams = {
	isDeleted: false,
	srcText: '',
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	isSend: false,
	equipment: {
		data: initialProtectiveEquipment,
		isLoading: false,
		isSuccess: false,
		hasError: false,
	},
	dicts: [],
	equipments: [],
	equipmentXML: null,
	removeXML: null,
	recoverXML: {
		open: false,
		data: null,
		response: null,
		error: null,
	},
	editXML: null,
	editSuccess: false,
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
	terminationReasons: [],
	card: {
		submitted: false,
	},
	reorganize: {
		checkData: null,
		loading: false,
		error: null,
		xml: null,
		xmlLoading: false,
		result: null,
		isSuccess: false,
	},
	binEmployersListToChange: null,
	reorgonizeXml: null,
	updateIin: {
		xmlForUpdateIin: null,
		flData: null,
		updateIinResponse: null,
		error: null,
	},
	params: initialParams,
	isEdit: false,
	packageLoading: {
		loading: false,
		error: null,
		xml: null,
		result: null,
	},
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_PROTECTIVE_EQUIPMENT_LOADING:
			return { ...state, equipment: { ...state.equipment, isLoading: payload } }
		case types.SET_PROTECTIVE_EQUIPMENT_SUCCESS:
			return { ...state, equipment: { ...state.equipment, isSuccess: payload } }
		case types.SET_PROTECTIVE_EQUIPMENTS:
			return {
				...state,
				equipments: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_CREATE_PROTECTIVE_EQUIPMENT_XML:
			return { ...state, createOccupationalRiskXML: payload }
		case types.SET_REMOVE_XML:
			return { ...state, removeXML: payload }
		case types.SET_EDIT_XML:
			return { ...state, editXML: payload }
		case types.SET_INITIAL_PROTECTIVE_EQUIPMENT:
			return { ...state, equipment: initialProtectiveEquipment }
		case types.SET_EDIT_PROTECTIVE_EQUIPMENT:
			return { ...state, dicts: payload }
		case types.SET_PROTECTIVE_EQUIPMENT_FIELDS:
			return { ...state, equipment: { ...state.equipment, data: { ...state.equipment.data, ...payload } } }
		case types.GET_DATA_BY_BIN_START:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: null,
					loading: true,
					xml: null,
					xmlLoading: false,
					isSuccess: false,
				},
			}
		case types.GET_EMPLOYER_PERMISSION_BY_BIN_START:
			return {
				...state,
				irsPermissionList: [],
			}
		case types.GET_EMPLOYER_PERMISSION_BY_BIN:
			return {
				...state,
				irsPermissionList: payload.irsPermissionList,
			}
		case types.GET_XML_TO_REORGANIZE_LOADING:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					xmlLoading: true,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_XML_TO_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.SEND_SIGNED_XML_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: true,
					result: payload,
				},
			}
		case types.SET_REORGANIZE_SUCCESS:
			return { ...state, reorganize: { ...state.reorganize, xmlLoading: false, isSuccess: false, result: payload } }
		case types.GET_REORGANIZE_XML:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					loading: false,
					xmlLoading: false,
					isSuccess: false,
					xml: payload,
				},
			}
		case types.GET_DATA_BY_BIN:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: payload,
					error: null,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.GET_DATA_BY_BIN_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.REORGANIZE_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_DATA_BY_BIN:
			return {
				...state,
				reorganize: initialState.reorganize,
			}
		case types.GET_EMPLOYERS_LIST_TO_CHANGE:
			return {
				...state,
				binEmployersListToChange: payload,
			}
		case types.GET_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					xmlForUpdateIin: payload,
				},
			}
		case types.GET_FL_DATA:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					flData: payload,
				},
			}
		case types.SEND_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					updateIinResponse: payload,
				},
			}
		case types.SET_ERROR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					error: payload,
				},
			}
		case types.RESET_UPDATE_IIN:
			return {
				...state,
				updateIin: initialState.updateIin,
			}
		case types.SET_RECOVER_XML:
			return {
				...state,
				recoverXML: {
					...state.recoverXML,
					...payload,
				},
			}
		case types.SET_EDIT_SUCCESS:
			return {
				...state,
				editSuccess: payload,
			}
		case types.RESET_EDITING:
			return {
				...state,
				editSuccess: false,
				editXML: null,
			}
		case types.SET_PARAMS:
			return {
				...state,
				params: { ...state.params, ...payload },
			}
		case types.GET_XML_TO_SIGN_SOME_LOADING:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: null,
				},
			}
		case types.GET_XML_TO_SIGN_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: payload,
				},
			}
		case types.GET_XML_TO_SIGN_SOME_ERROR:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: payload,
					loading: false,
					xml: null,
					result: null,
				},
			}
		case types.SEND_SIGNED_XML_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.SET_TERMINATE_XML:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					xml: payload,
					error: null,
					loading: false,
				},
			}
		case types.DELETE_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.RESET_PACKAGE:
			return {
				...state,
				selectedOccupationalRisks: [],
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: null,
				},
			}
		case types.SET_IS_EDIT:
			return {
				...state,
				isEdit: payload,
			}
		case types.RESET_PROTECTIVE_EQUIPMENT:
			return { ...state, equipment: { ...state.equipment, hasError: false, isSuccess: false, isLoading: false } }
		case types.SET_PROTECTIVE_EQUIPMENT_ERROR:
			return { ...state, equipment: { ...state.equipment, hasError: payload } }
		default:
			return state
	}
}
