import { handbookSelectors } from '@/entity/handbook/index'
import { createSelector } from 'reselect'
export const contract = (state) => state.contracts.contract.data
export const isLoading = (state) => state.contracts.isLoading
export const error = (state) => state.contracts.error
export const isSuccess = (state) => state.contracts.isSuccess
export const contracts = (state) => state.contracts.contracts
export const totalElements = (state) => state.contracts.totalElements
export const terminationReasons = (state) => state.contracts.terminationReasons
export const terminationDate = (state) => state.contracts.params.terminationDate
export const additionalContract = (state) => state.contracts.additionalContract.contract
export const options = createSelector(
	[
		handbookSelectors.contractTerms,
		handbookSelectors.positionsByKnz,
		handbookSelectors.positionsByKnzFull,
		handbookSelectors.positions,
		handbookSelectors.jobTypes,
		handbookSelectors.workingTimeModes,
		handbookSelectors.countries,
		handbookSelectors.regions,
		handbookSelectors.districts,
		handbookSelectors.populatedAreas,
		handbookSelectors.professionalSkills,
		handbookSelectors.personalQualities,
		handbookSelectors.remoteWorkTypes,
		handbookSelectors.contractTypes,
		handbookSelectors.workActivityTypes,
		handbookSelectors.workCondition,
		handbookSelectors.extraGuaranteesCollectiveContract,
		handbookSelectors.unionTypeCollectiveContract,
		handbookSelectors.workActivityTypeGroups,
	],
	(
		contractTerms,
		positionsByKnz,
		positionsByKnzFull,
		positions,
		jobTypes,
		workingTimeModes,
		countries,
		regions,
		districts,
		populatedAreas,
		professionalSkills,
		personalQualities,
		remoteWorkTypes,
		contractTypes,
		workActivityTypes,
		workCondition,
		extraGuaranteesCollectiveContract,
		unionTypeCollectiveContract,
		workActivityTypeGroups
	) => {
		return {
			contractTerms,
			positionsByKnz,
			positionsByKnzFull,
			positions,
			jobTypes,
			workingTimeModes,
			countries,
			regions,
			districts,
			populatedAreas,
			professionalSkills,
			personalQualities,
			remoteWorkTypes,
			contractTypes,
			workActivityTypes,
			workCondition,
			extraGuaranteesCollectiveContract,
			unionTypeCollectiveContract,
			workActivityTypeGroups,
		}
	}
)
