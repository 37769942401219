import { protectiveEquipmentActions, protectiveEquipmentThunks } from '@/entity'
import { api } from '@/shared'
import { message } from 'antd'
import { actions } from './actions'

export const createProtectiveEquipment = (data, isEdit = false) => {
	return async (dispatch, getState) => {
		try {
			const createData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'create',
				})),
			}
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			const created = await api.regulatoryInformation.protectiveEquipment.createProtectiveEquipment(
				isEdit ? data : createData
			)
			if (created.success) {
				const state = getState()
				if (isEdit) {
					dispatch(
						protectiveEquipmentActions.setProtectiveEquipments({
							...state.equipment,
							content: state.equipment?.map((occupationalRisk) => {
								return occupationalRisk
							}),
						})
					)
					dispatch(protectiveEquipmentActions.setProtectiveEquipments({ ...created.data }))
				}
				if (!isEdit) {
					const { params, size } = state.equipment
					dispatch(protectiveEquipmentThunks.getProtectiveEquipments(params, 0, size))
				}
				dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(false))
			}
			dispatch(protectiveEquipmentThunks.getProtectiveEquipments())
		} catch (error) {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(false))
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
			message.error('Ошибка сохранения данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const getProtectiveEquipment = (object) => {
	return async (dispatch) => {
		try {
			const protectiveEquipment = [object]
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			dispatch(protectiveEquipmentActions.setEditProtectiveEquipment(protectiveEquipment))
		} catch (error) {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const getProtectiveEquipments = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipmentActions.toggleError(null))
			dispatch(protectiveEquipmentActions.toggleLoading(true))
			dispatch(protectiveEquipmentActions.setParams(params))
			const data = await api.regulatoryInformation.protectiveEquipment.getAll({ ...params, page, size })
			dispatch(protectiveEquipmentActions.setProtectiveEquipments(data))
			dispatch(protectiveEquipmentActions.toggleSuccess(true))
			dispatch(protectiveEquipmentActions.toggleLoading(false))
		} catch (error) {
			dispatch(protectiveEquipmentActions.toggleError(error))
			dispatch(protectiveEquipmentActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(protectiveEquipmentActions.toggleLoading(false))
		}
	}
}

export const edit = (data) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			const editData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'update',
				})),
			}
			dispatch(protectiveEquipmentThunks.createProtectiveEquipment(editData, true))
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(true))
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(protectiveEquipmentActions.resetEditing())
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const remove = (data) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			const editData = {
				dicts: data.dicts?.map(({ creatorEmail, status, ...d }) => ({
					...d,
					status: 'delete',
				})),
			}
			dispatch(protectiveEquipmentThunks.createProtectiveEquipment(editData, true))
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(true))
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(protectiveEquipmentActions.resetEditing())
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const importFile = (data, isReplace) => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			const params = {
				dictName: 'ppm',
				isReplace,
			}
			const sendFile = await api.regulatoryInformation.protectiveEquipment.sendFile(params, data)
			if (sendFile.success) {
				dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(protectiveEquipmentActions.setProtectiveEquipmentSuccess(false))
			}
			dispatch(protectiveEquipmentThunks.getProtectiveEquipments())
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response : 'Неизвестная ошибка')
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const downloadFile = () => {
	return async (dispatch) => {
		try {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(true))
			const res = api.regulatoryInformation.protectiveEquipment.getDownload()
			console.log(res)
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response : 'Неизвестная ошибка')
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		} finally {
			dispatch(protectiveEquipmentActions.setProtectiveEquipmentLoading(false))
		}
	}
}

export const getDataByBin = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getDataByBinStart())
			const response = await api.occupationalRisks.getDataByBin(params)
			dispatch(actions.getDataByBin(response))
		} catch (error) {
			dispatch(actions.getDataByBinError(error?.response?.data?.message))
		}
	}
}
// toDo нужен будет в дальнейшем
/*export const autosave = (payload, occupationalRisk, isSendToEmployee) => {
	return async (dispatch) => {
		try {
			// added saved api
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			// toDo need add dispatch
			message.error(err ? err : 'Неизвестная ошибка')
		}
	}
}*/
