import { Label, Input, Error, SelectList, Checkbox, Textarea } from '@/ui/atoms'
import {
	Select,
	MultiSelect,
	SelectAndInput,
	SelectSearch,
	MultipleSelect,
	SearchSelect,
	SelectTree,
	InputNumber,
} from '@/ui'
import { Tooltip } from 'antd'
import { Info } from '@/app/icons'
import styles from './style.module.scss'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@/components/DatePicker'
import { TwoRadio } from '@/ui/atoms/twoRadio'

const labelSides = {
	right: styles.right,
	left: styles.left,
	top: styles.top,
}
export const Field = ({
	label,
	name,
	isRequired,
	firstField = false,
	onCustomChange,
	classNameError = '',
	error,
	fieldType = 'input',
	labelSize = 'default',
	labelSide = '',
	labelClassName = '',
	wrapperClassName = '',
	hint = '',
	...props
}) => {
	const { t } = useTranslation()
	const renderField = useMemo(() => {
		switch (fieldType) {
			case 'input':
				return <Input name={name} {...props} />
			case 'inputNumber':
				return <InputNumber name={name} {...props} />
			case 'datePicker':
				return <DatePicker onCustomChange={onCustomChange} name={name} {...props} />
			case 'selectList':
				return <SelectList name={name} {...props} />
			case 'select':
				return <Select onCustomChange={onCustomChange} required={isRequired} name={name} {...props} />
			case 'selectTree':
				return <SelectTree required={isRequired} name={name} {...props} />
			case 'selectSearch':
				return <SelectSearch onCustomChange={onCustomChange} required={isRequired} name={name} {...props} />
			case 'selectSearchApi':
				return <SearchSelect required={isRequired} name={name} {...props} />
			case 'selectAndInput':
				return <SelectAndInput name={name} {...props} />
			case 'checkbox':
				return <Checkbox name={name} {...props} />
			case 'multiSelect':
				return <MultiSelect name={name} {...props} />
			case 'multipleSelect':
				return <MultipleSelect name={name} {...props} />
			case 'textarea':
				return <Textarea name={name} required={isRequired} {...props} />
			case 'twoRadio':
				return <TwoRadio name={name} required={isRequired} {...props} />
			default:
				return `This type doesn't support`
		}
	}, [fieldType, isRequired, name, props])

	return (
		<div className={`${styles.field} ${labelSides[labelSide] || ''} ${wrapperClassName}`}>
			{firstField && renderField}
			{hint ? (
				<Tooltip placement="top" color="white" overlayInnerStyle={{ color: '#5B6880', width: '329px' }} title={hint}>
					<label name={name} className={labelClassName}>
						{label}
						{isRequired ? ' *' : ''}
						<Info className={styles.hintIcon} />
					</label>
				</Tooltip>
			) : (
				<Label name={name} size={labelSize} className={labelClassName}>
					{label}
					{isRequired ? ' *' : ''}
				</Label>
			)}
			{!firstField && renderField}
			{error && (
				<Error classNameError={classNameError}>
					{typeof error === 'string' ? error : t(`validation.${error.key}`, { value: error.value })}
				</Error>
			)}
		</div>
	)
}
