import { occupationalRisksActions, occupationalRisksThunks } from '@/entity'
import { handbookThunks } from '@/entity/handbook'
import { api } from '@/shared'
import { message } from 'antd'
import { actions } from './actions'
import { filterOneKato } from '@/entity/handbook/thunks'

export const createOccupationalRisk = (data, isEdit = false) => {
	return async (dispatch, getState) => {
		try {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(true))
			if (data.isResident) data.foreignLaborInfo = null
			const created = await api.occupationalRisks.createOccupationalRisk(data)
			if (created.success) {
				const state = getState()
				if (isEdit) {
					dispatch(
						occupationalRisksActions.setOccupationalRisk({
							...state.occupationalRisks,
							content: state.occupationalRisks?.map((occupationalRisk) => {
								return occupationalRisk
							}),
						})
					)
					dispatch(occupationalRisksActions.setOccupationalRisk({ ...created.data }))
				}
				if (!isEdit) {
					const { params, size } = state.occupationalRisks
					dispatch(occupationalRisksThunks.getOccupationalRisk(params, 0, size))
				}
				dispatch(occupationalRisksActions.setOccupationalRiskSuccess(true))
				message.success('Операция выполнена успешно', 10)
			} else {
				dispatch(occupationalRisksActions.setOccupationalRiskSuccess(false))
			}
		} catch (error) {
			dispatch(occupationalRisksActions.setOccupationalRiskSuccess(false))
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
			message.error('Ошибка сохранения данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
		} finally {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		}
	}
}

export const getOccupationalRisk = (id, isLocal, localOrRemote = false) => {
	return async (dispatch) => {
		try {
			if (!!id) {
				dispatch(occupationalRisksActions.setOccupationalRiskLoading(true))
				const content = localOrRemote
					? await api.occupationalRisks.getOccupationalRisk(id)
					: await api.occupationalRisks.get(id, isLocal)
				const { ddistrictCode, dregionCode, dlocalityCode, dpositionCode, dcountryCode } = content
				let position, district, region, locality, country
				try {
					;[district, region, locality] = await Promise.all(
						[ddistrictCode, dregionCode, dlocalityCode].map(async (item) =>
							item ? await filterOneKato(item) : Promise.resolve(null)
						)
					)
				} catch (err) {
					dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
				}

				try {
					position = dpositionCode ? await api.shared.getPosition({ code: dpositionCode }) : null
				} catch (err) {
					dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
				}

				try {
					country = dcountryCode ? await api.shared.getCountry({ code: dcountryCode }) : null
				} catch (err) {
					dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
				}
				dispatch(actions.setOccupationalRisk({ ...content, district, region, locality, position, country }))
			}
		} catch (error) {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		} finally {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		}
	}
}

export const getBranches = () => {
	return async (dispatch) => {
		try {
			dispatch(occupationalRisksActions.getBranchesLoading(true))
			const content = await api.occupationalRisks.getBranches()
			dispatch(occupationalRisksActions.getBranches(content))
		} catch (error) {
			dispatch(occupationalRisksActions.getBranchesLoading(false))
		} finally {
			dispatch(occupationalRisksActions.getBranchesLoading(false))
		}
	}
}

export const getStaff = (bin) => {
	return async (dispatch) => {
		try {
			dispatch(occupationalRisksActions.getStaffLoading(true))
			const content = await api.occupationalRisks.getStaff(bin)
			dispatch(occupationalRisksActions.getStaff(content))
		} catch (error) {
			dispatch(occupationalRisksActions.getStaffLoading(false))
		} finally {
			dispatch(occupationalRisksActions.getStaffLoading(false))
		}
	}
}

export const getHarmful = () => {
	return async (dispatch) => {
		try {
			dispatch(occupationalRisksActions.getHarmfulLoading(true))
			const content = await api.occupationalRisks.getHarmful()
			dispatch(occupationalRisksActions.getHarmfulSuccess(content))
		} catch (error) {
			dispatch(occupationalRisksActions.getHarmfulLoading(false))
		} finally {
			dispatch(occupationalRisksActions.getHarmfulLoading(false))
		}
	}
}

export const getOccupationalRisks = (params, page, size) => {
	return async (dispatch) => {
		try {
			dispatch(occupationalRisksActions.toggleError(null))
			dispatch(occupationalRisksActions.toggleLoading(true))
			dispatch(occupationalRisksActions.setParams(params))
			const data = await api.occupationalRisks.getAll(params, { page, size })
			dispatch(occupationalRisksActions.setOccupationalRisk(data))
			dispatch(occupationalRisksActions.toggleSuccess(true))
			dispatch(occupationalRisksActions.toggleLoading(false))
		} catch (error) {
			dispatch(occupationalRisksActions.toggleError(error))
			dispatch(occupationalRisksActions.toggleSuccess(false))
			message.error(error?.response?.data?.message)
		} finally {
			dispatch(occupationalRisksActions.toggleLoading(false))
		}
	}
}

export const generateRemoveXML = (externalId) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setOccupationalRiskLoading(true))
			const occupationalRiskXml = await api.occupationalRisks.deleteXml(externalId)
			dispatch(actions.setRemoveXML(occupationalRiskXml))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setOccupationalRiskSuccess(false))
		} finally {
			dispatch(actions.setOccupationalRiskLoading(false))
		}
	}
}

export const generateRecoverXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setOccupationalRiskLoading(true))
			const xml = await api.occupationalRisks.generateRecoverXml(data)
			dispatch(actions.setRecoverXML({ data: xml }))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setOccupationalRiskSuccess(false))
		} finally {
			dispatch(actions.setOccupationalRiskLoading(false))
		}
	}
}

export const recover = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setOccupationalRiskLoading(true))
			const state = getState()
			const { occupationalRiskNumber, dateFrom, bin, iin } = state.occupationalRisks?.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: occupationalRiskNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
			}
			const response = await api.occupationalRisks.recoverXml({ ...data, paramMap })
			dispatch(actions.setRecoverXML({ data: null, open: false, response, error: null }))
		} catch (error) {
			dispatch(
				actions.setRecoverXML({
					data: null,
					open: false,
					error: error.response ? error.response.data.message : 'Неизвестная ошибка',
					response: null,
				})
			)
		} finally {
			dispatch(actions.setOccupationalRiskLoading(false))
		}
	}
}

export const generateExternalRemoveXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(actions.setOccupationalRiskLoading(true))
			const occupationalRiskXml = await api.occupationalRisks.deleteExternalXml(data)
			dispatch(actions.setRemoveXML(occupationalRiskXml))
		} catch (error) {
			message.error('Ошибка удаления данных:' + (error.response ? error.response.data.message : 'Неизвестная ошибка'))
			dispatch(actions.setOccupationalRiskSuccess(false))
		} finally {
			dispatch(actions.setOccupationalRiskLoading(false))
		}
	}
}

export const edit = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(true))
			const state = getState()
			const { externalId, occupationalRiskNumber, dateFrom, bin, iin } = state.occupationalRisks?.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: occupationalRiskNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
				isEdit: true,
			}
			dispatch(occupationalRisksActions.setOccupationalRiskSuccess(true))
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		} catch (error) {
			message.error('Ошибка обновления данных:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.resetEditing())
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		} finally {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		}
	}
}

export const generateEditXML = (data) => {
	return async (dispatch) => {
		try {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(true))
			if (data.isResident) data.foreignLaborInfo = null
			const occupationalRiskXml = await api.occupationalRisks.getEditXml(data)
			dispatch(actions.setEditXML(occupationalRiskXml))
		} catch (err) {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		} finally {
			dispatch(occupationalRisksActions.setOccupationalRiskLoading(false))
		}
	}
}

export const remove = (data) => {
	return async (dispatch, getState) => {
		try {
			dispatch(actions.setOccupationalRiskLoading(true))
			const state = getState()
			const { occupationalRiskNumber, dateFrom, bin, iin } = state.occupationalRisks.data
			const { companyName } = state.company?.company
			const paramMap = {
				cntrname: occupationalRiskNumber,
				cntrdate: dateFrom,
				orgfrom: companyName,
				fromBin: bin,
				toIin: iin,
			}
			await api.occupationalRisks.remove({ ...data, paramMap })
			message.success('Данные удалены успешно', 10)
			dispatch(actions.setRemoveXML(null))
			const { params, size } = state.occupationalRisks
			dispatch(actions.setOccupationalRiskSuccess(true))
		} catch (error) {
			message.error('Ошибка:' + error.response ? error.response.data.message : 'Неизвестная ошибка')
			dispatch(actions.setOccupationalRiskSuccess(false))
		} finally {
			dispatch(actions.setOccupationalRiskSuccess(false))
		}
	}
}

export const getOptions = () => {
	return (dispatch, getState) => {
		const {
			contractTerms,
			positions,
			jobTypes,
			workingTimeModes,
			countries,
			districts,
			professionalSkills,
			personalQualities,
			remoteWorkTypes,
			contractTypes,
			sendStatuses,
			esutdRegErrors,
			socialLeaveTypes,
			restoreOrganizationTypes,
			restoreOrganizationCourts,
			positionsByKnz,
			contractStatuses,
			contractStates,
			workActivityTypes,
			workCondition,
			extraGuaranteesCollectiveContract,
			unionTypeCollectiveContract,
			workActivityTypeGroups,
			nonresidentAttractingBasis,
			nonresidentEducation,
			ccEnterpriseType,
			ccOwnershipType,
		} = getState().handbook

		const terminationReasons = getState().contracts.terminationReasons

		if (!contractStatuses.length) dispatch(handbookThunks.getContractStatuses())
		if (!contractStates.length) dispatch(handbookThunks.getContractStates())
		if (!contractTerms.length) dispatch(handbookThunks.getContractTerms())
		if (!positionsByKnz.length) dispatch(handbookThunks.getPositionsByKnz())
		if (!workCondition.length) dispatch(handbookThunks.getWorkCondition())
		if (!extraGuaranteesCollectiveContract.length) dispatch(handbookThunks.getExtraGuaranteesCollectiveContract())
		if (!unionTypeCollectiveContract.length) dispatch(handbookThunks.getUnionTypeCollectiveContract())
		if (!socialLeaveTypes.length) dispatch(handbookThunks.getSocialLeaveTypes())
		if (!restoreOrganizationTypes.length) dispatch(handbookThunks.getRestoreOrganizationTypes())
		if (!restoreOrganizationCourts.length) dispatch(handbookThunks.getRestoreOrganizationCourts())
		if (!positions.length) dispatch(handbookThunks.getPositions())
		if (!jobTypes.length) dispatch(handbookThunks.getJobTypes())
		if (!workingTimeModes.length) dispatch(handbookThunks.getWorkingTimeModes())
		if (!countries.length) dispatch(handbookThunks.getCountries())
		if (!districts.length) dispatch(handbookThunks.getDistrictsAuth())
		if (!professionalSkills.length) dispatch(handbookThunks.getProfessionalSkills())
		if (!personalQualities.length) dispatch(handbookThunks.getPersonalQualities())
		if (!remoteWorkTypes.length) dispatch(handbookThunks.getRemoteWorkTypes())
		if (!sendStatuses.length) dispatch(handbookThunks.sendStatuses())
		if (!esutdRegErrors.length) dispatch(handbookThunks.esutdRegErrors())
		if (!contractTypes.length) dispatch(handbookThunks.getContractTypes())
		if (!terminationReasons?.length) dispatch(occupationalRisksThunks.getTerminationReasons())
		if (!workActivityTypes.length) dispatch(handbookThunks.getWorkActivityTypes())
		if (!workActivityTypeGroups.length) dispatch(handbookThunks.getWorkActivityGroup())
		if (!nonresidentAttractingBasis.length) dispatch(handbookThunks.getNonresidentAttractingBasis())
		if (!nonresidentEducation.length) dispatch(handbookThunks.getNonresidentEducation())
		if (!ccEnterpriseType.length) dispatch(handbookThunks.getCcEnterpriseType())
		if (!ccOwnershipType.length) dispatch(handbookThunks.getCcOwnershipType())
	}
}

export const updateInitialOccupationalRisk = () => {
	return (dispatch, getState) => {
		const state = getState()
		const user = state.user.user
		const company = state.company.companies[0]
		dispatch(actions.setOccupationalRiskFields({ bin: user?.iin, companyName: company?.companyName }))
	}
}

export const getDataByBin = (params) => {
	return async (dispatch) => {
		try {
			dispatch(actions.getDataByBinStart())
			const response = await api.occupationalRisks.getDataByBin(params)
			dispatch(actions.getDataByBin(response))
		} catch (error) {
			dispatch(actions.getDataByBinError(error?.response?.data?.message))
		}
	}
}

export const deleteOccupationalRisks = (body, params) => async (dispatch) => {
	try {
		dispatch(occupationalRisksActions.getXmlToSignSomeLoading())
		const response = await api.occupationalRisks.removeOccupationalRisks(body, params)
		dispatch(occupationalRisksActions.deleteOccupationalRisks(response || {}))
	} catch (error) {
		dispatch(occupationalRisksActions.getXmlToSignSomeError(error?.response?.data?.message || 'Неизвестная ошибка'))
		message.error(error.response ? error.response.data.message : 'Неизвестная ошибка')
	}
}

export const autosave = (payload, occupationalRisk, isSendToEmployee) => {
	return async (dispatch) => {
		try {
			// added saved api
		} catch (error) {
			const err = error?.response?.data?.message || 'Неизвестная ошибка'
			// toDo need add dispatch
			message.error(err ? err : 'Неизвестная ошибка')
		}
	}
}
