import styles from './OccupationalRisks.module.scss'
import { Modal, ModalBackButton } from '@/components/Modal'
import { Title } from '@/ui'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OccupationalRisksForm } from './form/OccupationalRisksForm'
import { getXmlForRequest } from '@/shared/api/rest/personalAffairs'
import { useDigitalSign } from '@/shared'
import { formFieldNames } from '@/entity'
import { useDispatch } from 'react-redux'
import { LoadingBlocker } from '@/components/Loader'

export const OccupationalRisksModalFive = ({ page = 0, size = 10, onClose }) => {
	const { t } = useTranslation()
	const [xml, setXml] = useState(null)
	const { successPayload, onSign, isLoading, failedPayload, onClearPayload } = useDigitalSign()
	const [fio, setFio] = useState('')
	const dispatch = useDispatch()
	const [sendingLoading, setSendingLoading] = useState(false)

	const getXml = async (data) => {
		setFio(data[formFieldNames.employeeInitials])
		setXml(await getXmlForRequest(data))
	}

	return (
		<>
			{(isLoading || sendingLoading) && <LoadingBlocker />}

			<Modal windowClassName={styles.modal}>
				<ModalBackButton onClick={onClose} />
				<div className={`${styles.wrapper} scroll`}>
					<div className={styles.header}>
						<Title className={styles.title}>{t('occupational_risks_assessment.steps.step_1.add_object_title')}</Title>
					</div>
					<OccupationalRisksForm onClose={onClose} onSubmit={getXml} />
				</div>
			</Modal>
		</>
	)
}
