import { Formik, Field as FormikField, Form as FormikForm, useFormikContext } from 'formik'
import { Button } from '@/ui'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const ContractsFormOra = () => {
	const { t } = useTranslation()

	return (
		<FormikForm>
			<Button type="submit" onClick={() => console.log('submit')} disabled>
				{t('occupational_risks_assessment.contracts')}
			</Button>
		</FormikForm>
	)
}
