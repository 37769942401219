import * as types from './types'

export const initialOccupationalRisks = {
	address: 'string',
	bin: 'string',
	externalId: 'string',
	orgName: 'string',
	orgNameKz: 'string',
	orgs: [
		{
			address: 'string',
			bin: 'string',
			externalId: 'string',
			isHead: false,
			kato: 'string',
			oked: {
				code: 'string',
				nameKz: 'string',
				nameRu: 'string',
			},
			orgNameKz: 'string',
			orgNameRu: 'string',
			secondryOked: [
				{
					code: 'string',
					nameKz: 'string',
					nameRu: 'string',
				},
			],
			staffs: [
				{
					category: 'string',
					count: 0,
					deptCode: 'string',
					dicts: {
						equipmentsCodes: [
							{
								bin: 'string',
								code: 'string',
								commissioningDate: '2024-12-05',
								createDate: '2024-12-05',
								creatorEmail: 'string',
								isIndefinitely: false,
								isWithinServiceLife: false,
								name: 'string',
								serviceLife: 0,
								status: 'string',
								updateDate: '2024-12-05',
							},
						],
						harmfulCodes: [
							{
								code: 'string',
								nameKz: 'string',
								nameRu: 'string',
							},
						],
						ppmCodes: [
							{
								bin: 'string',
								code: 'string',
								createDate: '2024-12-05',
								creatorEmail: 'string',
								isCert: false,
								isCompliantWithStandards: false,
								issueRateInMonths: 0,
								nameKz: 'string',
								nameRu: 'string',
								status: 'string',
								updateDate: '2024-12-05',
							},
						],
					},
					group: 'string',
					parentDeptCode: 'string',
					position: 'string',
					professionGroupExternalId: 'string',
				},
			],
			staus: 'string',
		},
	],
	plannedDays: 0,
	status: 'string',
	totalServiceCost: 0,
}

const initialParams = {
	bin: '',
	companyName: '',
	occupationalRiskNumber: '',
	dateFrom: '',
	dateTo: '',
	employeeName: '',
	establishedPost: '',
	registerDate: '',
	sortDirection: '',
	sortField: '',
	srcText: '',
	terminationDate: null,
}

const initialState = {
	isLoading: false,
	isSuccess: false,
	error: null,
	isSend: false,
	ora: {
		data: initialOccupationalRisks,
		isLoading: false,
		isSuccess: false,
		hasError: false,
	},
	harmful: [],
	staff: {
		professionGroupsDtos: [],
		status: null,
	},
	branches: [],
	irsPermissionList: [],
	occupationalRisks: [],
	selectedOccupationalRisks: [],
	createOccupationalRiskXML: null,
	removeXML: null,
	recoverXML: {
		open: false,
		data: null,
		response: null,
		error: null,
	},
	editXML: null,
	editSuccess: false,
	pageable: null,
	totalPages: 0,
	totalElements: 0,
	last: false,
	number: 0,
	sort: null,
	size: 0,
	first: false,
	numberOfElements: 0,
	empty: false,
	terminationReasons: [],
	card: {
		submitted: false,
	},
	reorganize: {
		checkData: null,
		loading: false,
		error: null,
		xml: null,
		xmlLoading: false,
		result: null,
		isSuccess: false,
	},
	binEmployersListToChange: null,
	reorgonizeXml: null,
	updateIin: {
		xmlForUpdateIin: null,
		flData: null,
		updateIinResponse: null,
		error: null,
	},
	params: initialParams,
	socialLeaveIsLoading: false,
	socialLeaveIsSuccess: false,
	prolongationIsSuccess: false,
	prolongationIsLoading: false,
	isEdit: false,
	socialLeaveXML: null,
	prolongationXML: null,
	packageLoading: {
		loading: false,
		error: null,
		xml: null,
		result: null,
	},
	terminationXML: null,
	terminationIsLoading: false,
	terminationIsSuccess: false,
	etd: {
		isLoading: false,
		isSuccess: false,
		list: {
			data: [],
			numberOfElements: 10,
			totalElements: 30,
			totalPages: 3,
		},
		filter: {
			status: null,
			iin: null,
			date: null,
		},
		params: {
			page: 0,
			size: 10,
			rowsPerPage: [
				{
					size: 10,
					label: '10',
				},
				{
					size: 50,
					label: '50',
				},
				{
					size: 100,
					label: '100',
				},
			],
		},
		error: null,
		currentEtd: {
			data: null,
			loading: false,
			error: null,
		},
		currentEtdEdit: {
			data: null,
			loading: false,
			error: null,
		},
		saveProject: {
			data: null,
			loading: false,
			error: null,
		},
		sendToEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		declineEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		sendForImprovementEmployee: {
			data: null,
			loading: false,
			error: null,
		},
		declineEmployer: {
			data: null,
			loading: false,
			error: null,
		},
		deleteEtd: {
			data: null,
			loading: false,
			error: null,
		},
		status: null,
		image: null,
		key: null,
	},
}

export const reducer = (state = initialState, actions) => {
	const { type, payload } = actions
	switch (type) {
		case types.TOGGLE_LOADING:
			return { ...state, isLoading: payload }
		case types.TOGGLE_SUCCESS:
			return { ...state, isSuccess: payload }
		case types.TOGGLE_ERROR:
			return { ...state, error: payload }
		case types.SET_OCCUPATIONAL_RISK_LOADING:
			return { ...state, ora: { ...state.ora, isLoading: payload } }
		case types.SET_OCCUPATIONAL_RISK_SUCCESS:
			return { ...state, ora: { ...state.ora, isSuccess: payload } }
		case types.SET_OCCUPATIONAL_RISKS:
			return {
				...state,
				occupationalRisks: payload.content,
				pageable: payload.pageable,
				totalPages: payload.totalPages,
				totalElements: payload.totalElements,
				last: payload.last,
				number: payload.number,
				sort: payload.sort,
				size: payload.size,
				first: payload.first,
				numberOfElements: payload.numberOfElements,
				empty: payload.empty,
			}
		case types.SET_CREATE_OCCUPATIONAL_RISK_XML:
			return { ...state, createOccupationalRiskXML: payload }
		case types.SET_REMOVE_XML:
			return { ...state, removeXML: payload }
		case types.SET_EDIT_XML:
			return { ...state, editXML: payload }
		case types.SET_INITIAL_OCCUPATIONAL_RISK:
			return { ...state, ora: initialOccupationalRisks }
		case types.SET_OCCUPATIONAL_RISK_FIELDS:
			return { ...state, ora: { ...state.ora, data: { ...state.ora.data, ...payload } } }
		case types.GET_DATA_BY_BIN_START:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: null,
					loading: true,
					xml: null,
					xmlLoading: false,
					isSuccess: false,
				},
			}
		case types.GET_EMPLOYER_PERMISSION_BY_BIN_START:
			return {
				...state,
				irsPermissionList: [],
			}
		case types.GET_EMPLOYER_PERMISSION_BY_BIN:
			return {
				...state,
				irsPermissionList: payload.irsPermissionList,
			}
		case types.GET_XML_TO_REORGANIZE_LOADING:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					xmlLoading: true,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_XML_TO_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.SEND_SIGNED_XML_REORGANIZE:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					xmlLoading: false,
					xml: null,
					isSuccess: true,
					result: payload,
				},
			}
		case types.SET_REORGANIZE_SUCCESS:
			return { ...state, reorganize: { ...state.reorganize, xmlLoading: false, isSuccess: false, result: payload } }
		case types.GET_BRANCHES:
			return { ...state, branches: payload }
		case types.GET_REORGANIZE_XML:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: null,
					loading: false,
					xmlLoading: false,
					isSuccess: false,
					xml: payload,
				},
			}
		case types.GET_DATA_BY_BIN:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: payload,
					error: null,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.GET_DATA_BY_BIN_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					checkData: null,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.REORGANIZE_ERROR:
			return {
				...state,
				reorganize: {
					...state.reorganize,
					error: payload,
					loading: false,
					xmlLoading: false,
					xml: null,
					isSuccess: false,
				},
			}
		case types.CLEAR_DATA_BY_BIN:
			return {
				...state,
				reorganize: initialState.reorganize,
			}
		case types.GET_EMPLOYERS_LIST_TO_CHANGE:
			return {
				...state,
				binEmployersListToChange: payload,
			}
		case types.GET_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					xmlForUpdateIin: payload,
				},
			}
		case types.GET_FL_DATA:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					flData: payload,
				},
			}
		case types.SEND_XML_FOR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					updateIinResponse: payload,
				},
			}
		case types.SET_ERROR_UPDATE_IIN:
			return {
				...state,
				updateIin: {
					...state.updateIin,
					error: payload,
				},
			}
		case types.RESET_UPDATE_IIN:
			return {
				...state,
				updateIin: initialState.updateIin,
			}
		case types.SET_RECOVER_XML:
			return {
				...state,
				recoverXML: {
					...state.recoverXML,
					...payload,
				},
			}
		case types.SET_EDIT_SUCCESS:
			return {
				...state,
				editSuccess: payload,
			}
		case types.RESET_EDITING:
			return {
				...state,
				editSuccess: false,
				editXML: null,
			}
		case types.SET_PARAMS:
			return {
				...state,
				params: payload,
			}
		case types.GET_XML_TO_SIGN_SOME_LOADING:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: null,
				},
			}
		case types.GET_XML_TO_SIGN_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					loading: true,
					xml: payload,
				},
			}
		case types.GET_XML_TO_SIGN_SOME_ERROR:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: payload,
					loading: false,
					xml: null,
					result: null,
				},
			}
		case types.SEND_SIGNED_XML_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.SET_TERMINATE_XML:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					xml: payload,
					error: null,
					loading: false,
				},
			}
		case types.DELETE_PACKAGE_SOME:
			return {
				...state,
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: payload,
				},
			}
		case types.RESET_PACKAGE:
			return {
				...state,
				selectedOccupationalRisks: [],
				packageLoading: {
					...state.packageLoading,
					error: null,
					xml: null,
					loading: false,
					result: null,
				},
			}
		case types.SET_IS_EDIT:
			return {
				...state,
				isEdit: payload,
			}
		case types.RESET_OCCUPATIONAL_RISK:
			return { ...state, ora: { ...state.ora, hasError: false, isSuccess: false, isLoading: false } }
		case types.SET_OCCUPATIONAL_RISK_ERROR:
			return { ...state, ora: { ...state.ora, hasError: payload } }
		case types.GET_HARMFUL_SUCCESS:
			return { ...state, harmful: payload }
		case types.GET_STAFF:
			return { ...state, staff: payload }
		default:
			return state
	}
}
