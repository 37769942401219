import { connect, Field as FormikField } from 'formik'
import { Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { formFieldNames } from '@/entity'
import React from 'react'
import RadioStaff from '@/components/RadioStaff/RadioStaff'

export const ProvisionPPE = connect(({ formik: { setFieldValue, values }, isFormDirty, genders, currentLanguage }) => {
	const { t } = useTranslation()
	const stepText = 'occupational_risks_assessment.steps.step_5.'

	return (
		<section>
			{/*<h5>{t('accidentsPage.register.victim')} </h5>*/}
			<Grid marginBottom={2} marginTop={1} container spacing={4} columns={8}>
				<Grid item xs={8}>
					<FormikField name={formFieldNames.employeeInitials}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="selectSearch"
								type="text"
								isRequired
								label={t(stepText + 'field_1')}
								placeholder={t(stepText + 'field_1')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
			</Grid>
			<Grid marginBottom={2} container spacing={4} columns={16}>
				<Grid item xs={5}>
					<FormikField name={'object'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								options={genders}
								isRequired
								label={t(stepText + 'field_2')}
								placeholder={t(stepText + 'field_2')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
				<Grid item xs={5}>
					<FormikField name={'fact_address'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								options={genders}
								isRequired
								label={t(stepText + 'field_3')}
								placeholder={t(stepText + 'field_3')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
			</Grid>
			<Grid marginBottom={2} container columns={8}>
				<Grid item xs={8}>
					<FormikField name={'fact_address'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								options={genders}
								isRequired
								label={t(stepText + 'field_4')}
								placeholder={t(stepText + 'field_4')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
			</Grid>
			<Grid container marginBottom={2} spacing={4} columns={15}>
				<Grid item xs={5}>
					<FormikField name={'object'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="twoRadio"
								value={values['twoRadio']}
								firstId="1"
								secondId="2"
								isRequired
								label={t(stepText + 'field_5')}
								placeholder={t(stepText + 'field_5')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
				<Grid item xs={5}>
					<FormikField name={'fact_address'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="twoRadio"
								value={values['twoRadio']}
								firstId="1"
								secondId="2"
								isRequired
								label={t(stepText + 'field_6')}
								placeholder={t(stepText + 'field_6')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
				<Grid item xs={5}>
					<FormikField name={'fact_address'}>
						{({ field, meta: { touched, error } }) => (
							<Field
								fieldType="twoRadio"
								value={values['twoRadio']}
								firstId="1"
								secondId="2"
								isRequired
								label={t(stepText + 'field_7')}
								placeholder={t(stepText + 'field_7')}
								{...field}
								error={(touched || isFormDirty) && error}
							/>
						)}
					</FormikField>
				</Grid>
			</Grid>
		</section>
	)
})
