import { request } from '@/shared/api/request'

export const get = (payload) => {
	return request({ url: '/assessment', method: 'GET', data: payload })
}

export const getBranches = (payload) => {
	return request({ url: '/assessment/branches', method: 'GET', data: payload })
}

export const getStaff = (bin) => {
	return request({ url: '/assessment/staff', method: 'GET', params: { bin } })
}

export const getAll = (payload) => {
	return request({ url: '/assessment', method: 'GET', data: payload })
}

export const getHarmful = () => {
	return request({ url: 'dict/harmful', method: 'GET' })
}

export const getDataByBin = (params) => request({ url: '/occupationalRisks/reorganize/check', method: 'GET', params })
