export const SET_PROTECTIVE_EQUIPMENT_LOADING = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENT_LOADING'
export const SET_PROTECTIVE_EQUIPMENT = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENT'
export const SET_PROTECTIVE_EQUIPMENTS = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENTS'
export const SET_EDIT_PROTECTIVE_EQUIPMENT = 'protectiveEquipments/SET_EDIT_PROTECTIVE_EQUIPMENT'
export const SET_PROTECTIVE_EQUIPMENT_SUCCESS = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENT_SUCCESS'
export const SET_CREATE_PROTECTIVE_EQUIPMENT_XML = 'protectiveEquipments/SET_CREATE_PROTECTIVE_EQUIPMENT_XML'
export const SET_INITIAL_PROTECTIVE_EQUIPMENT = 'protectiveEquipments/SET_INITIAL_PROTECTIVE_EQUIPMENT'
export const SET_PROTECTIVE_EQUIPMENT_FIELDS = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENT_FIELDS'
export const SET_SELECTED_PROTECTIVE_EQUIPMENTS_STATE = 'protectiveEquipments/SET_SELECTED_PROTECTIVE_EQUIPMENTS_STATE'
export const DELETE_PROTECTIVE_EQUIPMENT_SOME = 'protectiveEquipments/DELETE_PROTECTIVE_EQUIPMENT_SOME'
export const RESET_PROTECTIVE_EQUIPMENT = 'protectiveEquipments/RESET_PROTECTIVE_EQUIPMENT'
export const SET_PROTECTIVE_EQUIPMENT_ERROR = 'protectiveEquipments/SET_PROTECTIVE_EQUIPMENT_ERROR'
export const TOGGLE_LOADING = 'protectiveEquipments/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'protectiveEquipments/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'protectiveEquipments/TOGGLE_ERROR'
export const SET_TERMINATE_XML = 'protectiveEquipments/SET_TERMINATE_XML'
export const SET_REMOVE_XML = 'protectiveEquipments/SET_REMOVE_XML'
export const SET_RECOVER_XML = 'protectiveEquipments/SET_RECOVER_XML'
export const SET_EDIT_XML = 'protectiveEquipments/SET_EDIT_XML'
export const GET_DATA_BY_BIN = 'protectiveEquipments/GET_DATA_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN = 'protectiveEquipments/GET_EMPLOYER_PERMISSION_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN_START = 'protectiveEquipments/GET_EMPLOYER_PERMISSION_BY_BIN_START'
export const GET_DATA_BY_BIN_START = 'protectiveEquipments/GET_DATA_BY_BIN_START'
export const GET_XML_TO_REORGANIZE_LOADING = 'protectiveEquipments/GET_XML_TO_REORGANIZE_LOADING'
export const CLEAR_XML_TO_REORGANIZE = 'protectiveEquipments/CLEAR_XML_TO_REORGANIZE'
export const SEND_SIGNED_XML_REORGANIZE = 'protectiveEquipments/SEND_SIGNED_XML_REORGANIZE'
export const SET_REORGANIZE_SUCCESS = 'protectiveEquipments/SET_REORGANIZE_SUCCESS'
export const REORGANIZE_ERROR = 'protectiveEquipments/REORGANIZE_ERROR'
export const GET_DATA_BY_BIN_ERROR = 'protectiveEquipments/GET_DATA_BY_BIN_ERROR'
export const CLEAR_DATA_BY_BIN = 'protectiveEquipments/CLEAR_DATA_BY_BIN'
export const GET_EMPLOYERS_LIST_TO_CHANGE = 'protectiveEquipments/GET_EMPLOYERS_LIST_TO_CHANGE'
export const GET_REORGANIZE_XML = 'protectiveEquipments/GET_REORGANIZE_XML'
export const GET_FL_DATA = 'protectiveEquipments/GET_FL_DATA'
export const GET_XML_FOR_UPDATE_IIN = 'protectiveEquipments/GET_XML_FOR_UPDATE_IIN'
export const SEND_XML_FOR_UPDATE_IIN = 'protectiveEquipments/SEND_XML_FOR_UPDATE_IIN'
export const RESET_UPDATE_IIN = 'protectiveEquipments/RESET_UPDATE_IIN'
export const SET_ERROR_UPDATE_IIN = 'protectiveEquipments/SET_ERROR_UPDATE_IIN'
export const SET_EDIT_SUCCESS = 'protectiveEquipments/SET_EDIT_SUCCESS'
export const RESET_EDITING = 'protectiveEquipments/RESET_EDITING'
export const SET_PARAMS = 'protectiveEquipments/SET_PARAMS'

// PACKAGE LOADING
export const GET_XML_TO_SIGN_SOME_LOADING = 'protectiveEquipments/GET_XML_TO_SIGN_SOME_LOADING'
export const GET_XML_TO_SIGN_SOME = 'protectiveEquipments/GET_XML_TO_SIGN_SOME'
export const GET_XML_TO_SIGN_SOME_ERROR = 'protectiveEquipments/GET_XML_TO_SIGN_SOME_ERROR'
export const SEND_SIGNED_XML_PACKAGE_SOME = 'protectiveEquipments/SEND_SIGNED_XML_PACKAGE_SOME'
export const DELETE_PACKAGE_SOME = 'protectiveEquipments/DELETE_PACKAGE_SOME'
export const RESET_PACKAGE = 'protectiveEquipments/RESET_PACKAGE'
export const SET_IS_EDIT = 'protectiveEquipments/SET_IS_EDIT'
