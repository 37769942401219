import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import React, { useEffect, useMemo, useState } from 'react'
import { Close8, Preference } from '@app/images'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { handbookSelectors } from '@/entity/handbook'
import { contractsThunks, userSelectors, contractsActions, contractsSelectors } from '@/entity'
import classes from './occupationalRisksSearch.module.scss'
import { DatePicker } from '@/components/DatePicker'
import { Select } from '@/ui'
import { Download, Unsign, Pencil, Delete } from '@app/icons'
import Box from '@mui/material/Box'
import { LoadingBlocker } from '@/components/Loader'
import { useDigitalSign } from '@/shared'
import { message } from 'antd'

const HrButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'capitalize',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	backgroundColor: '#17B67C',
	fontFamily: 'Inter',
	'&:hover': {
		backgroundColor: '#17B67C',
		boxShadow: 'none',
		color: 'white',
	},
})

const HrResetButton = styled(Button)({
	boxShadow: 'none',
	textTransform: 'none',
	fontSize: 14,
	padding: '12.5px 17px',
	borderRadius: '2px',
	lineHeight: '15.4px',
	fontFamily: 'Inter',
	height: '32px',
	fontWeight: 700,
	color: '#AAB8D1',
	'&:hover': {
		backgroundColor: 'transparent',
		boxShadow: 'none',
	},
})

const colors = ['#FFBC34', '#E65332']

const OccupationalRisksSearch = ({ filters, setFilters, fetchData, resetFilter, page, pageSize }) => {
	const { t } = useTranslation()
	const { successPayload, onSign, onClearPayload } = useDigitalSign()

	// справочники для расширенного поиска
	const statusList = useSelector(handbookSelectors.contractStatuses)
	const stateList = useSelector(handbookSelectors.contractStates)
	const typeList = useSelector(handbookSelectors.contractTypes)
	const activeRole = useSelector(userSelectors.activeRole)
	const isLoading = useSelector(contractsSelectors.isLoading)
	const isSuccess = useSelector(contractsSelectors.isSuccess)
	const contracts = useSelector(contractsSelectors.contracts)
	const sendStatuses = useSelector((state) => {
		return state.handbook.sendStatuses.map((item, index) => ({
			...item,
			icon: <Unsign fill={colors[index]} />,
		}))
	})
	const isEmployer = useMemo(() => activeRole === 'ROLE_EMPLOYER', [activeRole])
	const dispatch = useDispatch()
	const [advancedSearch, setAdvancedSearch] = useState(false)
	const [openImportModal, setImportModal] = useState(false)

	const updateFilter = (e, name) =>
		setFilters({ ...filters, [name]: e?.target?.value || (typeof e === 'string' && e) || '' })
	const closeModal = () => setImportModal(false)

	const selectedContracts = useSelector((state) => state.contracts.selectedContracts)

	const { loading, xml, error, result } = useSelector((state) => state.contracts.packageLoading)

	useEffect(() => {
		if (result) {
			message.success(t('success_alert'))
			dispatch(contractsActions.resetPackage())
			setOpenSignSome(false)
			setOpenDelete(false)
		}
	}, [result])

	useEffect(() => {
		if (error) {
			onClearPayload()
		}
	}, [error])

	const [openSignSome, setOpenSignSome] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)

	const signSome = () => {
		dispatch(
			contractsThunks.getXmlToSignSome({
				contractExternalIds: selectedContracts.map((contract) => contract.externalId),
			})
		)
	}

	const deleteContracts = () => {
		dispatch(contractsThunks.deleteContracts(selectedContracts.map((contract) => contract.externalId)))
	}

	useEffect(() => {
		if (xml) {
			onSign(xml)
		}
	}, [xml])

	useEffect(() => {
		if (successPayload) {
			dispatch(contractsThunks.sendSignedXmlPackageSome({ xmlData: successPayload }, {}, filters, page, pageSize))
		}
	}, [successPayload])

	return (
		<>
			<div className={classes.searchRow}>
				<div className={classes.searchField}>
					<div className={classes.searchField__left}>
						<IconButton type="submit" aria-label="search">
							<SearchIcon />
						</IconButton>
						<InputBase
							fullWidth
							placeholder={t('occupational_risks_assessment.search_placeholder')}
							inputProps={{
								'aria-label': 'search',
							}}
							value={filters.srcText}
							onChange={(e) => updateFilter(e, 'srcText')}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault()
									fetchData()
								}
							}}
						/>
					</div>
					<span className={classes.searchField__button} onClick={() => fetchData()}>
						{t('find')}
					</span>
				</div>

				<div className={classes.advancedSearch__button} onClick={() => setAdvancedSearch(!advancedSearch)}>
					{advancedSearch ? (
						<>
							<img src={Close8} className={classes.advancedSearch__icon} />
							{t('contracts_tab.advancedSearchHide')}
						</>
					) : (
						<>
							<img src={Preference} className={classes.advancedSearch__icon} />
							{t('contracts_tab.advancedSearch')}
						</>
					)}
				</div>

				{/*{!!contracts?.length && isSuccess && !isLoading && (
					<Box
						onClick={setImportModal.bind(null, true)}
						className={classes.export__wrapper}
						display="flex"
						alignItems="center"
					>
						<Download />
						<div className={classes.export__text}>{t('contracts_tab.export')}</div>
					</Box>
				)}*/}
			</div>
			{advancedSearch && (
				<div className={classes.advancedSearch__row}>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contract.info.td_number')}</div>
						<input
							type="text"
							className={classes.advancedSearch__input}
							value={filters.contractNumber}
							onChange={(e) => updateFilter(e, 'contractNumber')}
							placeholder={t('enter_number')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.contractor')}</div>
						<input
							type="text"
							className={classes.advancedSearch__input}
							value={filters.companyName || filters.employeeName}
							onChange={(e) => updateFilter(e, isEmployer ? 'employeeName' : 'companyName')}
							placeholder={t('enter_fio')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.position')}</div>
						<input
							type="text"
							className={classes.advancedSearch__input}
							value={filters.establishedPost}
							onChange={(e) => updateFilter(e, 'establishedPost')}
							placeholder={t('enter_position')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.status')}</div>
						<Select
							required
							options={statusList}
							inputClasses={classes.advancedSearch__input}
							iconClasses={classes.advancedSearch__selectIcon}
							value={filters.dcontractStatusCode || ''}
							onChange={(e) => updateFilter(e, 'dcontractStatusCode')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.state')}</div>
						<Select
							required
							options={stateList}
							inputClasses={classes.advancedSearch__input}
							iconClasses={classes.advancedSearch__selectIcon}
							value={filters.dcontractStateCode || ''}
							onChange={(e) => updateFilter(e, 'dcontractStateCode')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.type')}</div>
						<Select
							required
							options={typeList}
							inputClasses={classes.advancedSearch__input}
							iconClasses={classes.advancedSearch__selectIcon}
							value={filters.dcontractTypeCode || ''}
							onChange={(e) => updateFilter(e, 'dcontractTypeCode')}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.registration_date')}</div>
						<DatePicker
							wrapperClasses={classes.advancedSearch__input}
							inputClassName={classes.advancedSearch__datePickerInput}
							value={filters.registerDate}
							onChange={(e) => updateFilter(e, 'registerDate')}
							iconClasses={classes.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.conclusion_date')}</div>
						<DatePicker
							wrapperClasses={classes.advancedSearch__input}
							inputClassName={classes.advancedSearch__datePickerInput}
							value={filters.dateFrom}
							onChange={(e) => updateFilter(e, 'dateFrom')}
							iconClasses={classes.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('contracts_tab.expired_date')}</div>
						<DatePicker
							wrapperClasses={classes.advancedSearch__input}
							inputClassName={classes.advancedSearch__datePickerInput}
							value={filters.dateTo}
							onChange={(e) => updateFilter(e, 'dateTo')}
							iconClasses={classes.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('terminate_date_contract')}</div>
						<DatePicker
							wrapperClasses={classes.advancedSearch__input}
							inputClassName={classes.advancedSearch__datePickerInput}
							value={filters.terminationDate}
							onChange={(e) => updateFilter(e, 'terminationDate')}
							iconClasses={classes.advancedSearch__datepickerIcon}
						/>
					</div>
					<div className={classes.advancedSearch__field}>
						<div className={classes.advancedSearch__label}>{t('sign_subscription')}</div>
						<Select
							required
							options={sendStatuses}
							inputClasses={`${classes.advancedSearch__input} ${
								filters.dsendStatusCode ? classes.advancedSearch__input__icon : ''
							}`}
							iconClasses={classes.advancedSearch__selectIcon}
							value={filters.dsendStatusCode || ''}
							onChange={(e) => updateFilter(e, 'dsendStatusCode')}
							optionsStyles={{ width: '170%' }}
						/>
					</div>
					<div className={classes.advancedSearch__searchButtonsRow}>
						<HrResetButton variant="text" onClick={resetFilter}>
							{t('reset_all')}
						</HrResetButton>
						<HrButton style={{ height: '32px' }} variant="contained" onClick={() => fetchData()}>
							{t('search')}
						</HrButton>
					</div>
				</div>
			)}

			{loading && <LoadingBlocker />}
			{!!selectedContracts?.length && (
				<Box className={classes.sign__buttons__wrapper} display="flex" alignItems="center" justifyContent="flex-end">
					<HrButton
						onClick={setOpenSignSome.bind(null, true)}
						classes={{ root: classes.sign__button }}
						style={{ height: '32px' }}
						variant="contained"
					>
						<Pencil fill="#FFF" />
						<span className={classes.sign__button__text} style={{ textTransform: 'initial' }}>
							{t('actions.sign')}
						</span>
					</HrButton>
					<HrResetButton onClick={setOpenDelete.bind(null, true)} variant="text">
						<span className={classes.delete__icon}>
							<Delete fill="#5B6880" />
						</span>
						<span className={classes.delete__text}>{t('remove')}</span>
					</HrResetButton>
				</Box>
			)}
		</>
	)
}

export default OccupationalRisksSearch
