import { useTranslation } from 'react-i18next'
import { ConfirmModal, Title } from '@/ui'
import styles from './CreateStaffModal.module.scss'
import React from 'react'

export const CreateStaffModal = ({ onProcess, onCancel, isLoading }) => {
	const { t } = useTranslation()

	return (
		<ConfirmModal
			onProcess={onProcess}
			onCancel={onCancel}
			cancelButtonTitle={'cancel'}
			processButtonTitle={t('go_to')}
			hideBackButton
			priority={2}
			isLoading={isLoading}
		>
			<div className={styles.content}>
				<Title>{t('occupational_risks_assessment.steps.step_2.info_staff')}</Title>
			</div>
		</ConfirmModal>
	)
}
