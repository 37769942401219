import * as types from './types'
import { getProtectiveEquipments } from '@/entity/regulatoryInformation/model/protectiveEquipment/thunks'
import { SET_EDIT_PROTECTIVE_EQUIPMENT } from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setProtectiveEquipment: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENT,
		payload,
	}),
	setProtectiveEquipmentLoading: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENT_LOADING,
		payload,
	}),
	setProtectiveEquipmentSuccess: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENT_SUCCESS,
		payload,
	}),
	setProtectiveEquipments: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENTS,
		payload,
	}),
	setEditProtectiveEquipment: (payload) => ({
		type: types.SET_EDIT_PROTECTIVE_EQUIPMENT,
		payload,
	}),
	setCreateProtectiveEquipmentXML: (payload) => ({
		type: types.SET_CREATE_PROTECTIVE_EQUIPMENT_XML,
		payload,
	}),
	setRemoveXML: (payload) => ({
		type: types.SET_REMOVE_XML,
		payload,
	}),
	setRecoverXML: (payload) => ({
		type: types.SET_RECOVER_XML,
		payload,
	}),
	setEditXML: (payload) => ({
		type: types.SET_EDIT_XML,
		payload,
	}),
	getDataByBinStart: (payload) => ({
		type: types.GET_DATA_BY_BIN_START,
		payload,
	}),
	getDataByBin: (payload) => ({
		type: types.GET_DATA_BY_BIN,
		payload,
	}),
	getDataByBinError: (payload) => ({
		type: types.GET_DATA_BY_BIN_ERROR,
		payload,
	}),
	clearDataByBin: (payload) => ({
		type: types.CLEAR_DATA_BY_BIN,
		payload,
	}),
	setInitialProtectiveEquipment: () => ({
		type: types.SET_INITIAL_PROTECTIVE_EQUIPMENT,
	}),
	setProtectiveEquipmentFields: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENT_FIELDS,
		payload,
	}),
	setEditSuccess: (payload) => ({
		type: types.SET_EDIT_SUCCESS,
		payload,
	}),
	resetEditing: () => ({
		type: types.RESET_EDITING,
	}),
	setParams: (payload) => ({
		type: types.SET_PARAMS,
		payload,
	}),
	setSelectedProtectiveEquipments: (payload) => ({
		type: types.SET_SELECTED_PROTECTIVE_EQUIPMENTS_STATE,
		payload,
	}),
	// PACKAGE LOADING
	getXmlToSignSomeLoading: () => ({ type: types.GET_XML_TO_SIGN_SOME_LOADING }),
	getXmlToSignSome: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME,
		payload,
	}),
	getXmlToSignSomeError: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME_ERROR,
		payload,
	}),
	sendSignedXmlPackageSome: (payload) => ({
		type: types.SEND_SIGNED_XML_PACKAGE_SOME,
		payload,
	}),
	deleteProtectiveEquipments: (payload) => ({
		type: types.DELETE_PROTECTIVE_EQUIPMENT_SOME,
		payload,
	}),
	resetPackage: (payload) => ({
		type: types.RESET_PROTECTIVE_EQUIPMENT,
		payload,
	}),

	setProtectiveEquipmentError: (payload) => ({
		type: types.SET_PROTECTIVE_EQUIPMENT_ERROR,
		payload,
	}),
	resetProtectiveEquipment: () => ({
		type: types.RESET_PROTECTIVE_EQUIPMENT,
	}),
	setIsEdit: (payload) => ({
		type: types.SET_IS_EDIT,
		payload,
	}),
}
