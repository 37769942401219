import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import { Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ValidationSchemaContractORA } from '@/entity/contractsORA/lib/schemas'
import { ContractsFormOra } from '@/entity'

export const ContractsORA = () => {
	const { t } = useTranslation()
	const onSubmit = (data) => {
		console.log(data)
	}
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('ckp_occupational_risks_assessment')}>
				<Formik
					initialValues={{ objectAssessment: [0], objectORA: [0], membersOfCommissionORA: [0] }}
					validationSchema={ValidationSchemaContractORA}
					onSubmit={onSubmit}
				>
					<ContractsFormOra />
				</Formik>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
