export const tableColumns = [
	{
		id: 'number',
		numeric: false,
		label: 'occupational_risks_assessment.applications.applications_table.number',
	},
	{
		id: 'object',
		numeric: true,
		label: 'occupational_risks_assessment.applications.applications_table.object',
	},
	{
		id: 'date_app',
		numeric: true,
		label: 'occupational_risks_assessment.applications.applications_table.date_app',
	},
	{
		id: 'planned_dates',
		numeric: true,
		label: 'occupational_risks_assessment.applications.applications_table.planned_dates',
	},
	{
		id: 'total_cost',
		numeric: true,
		label: 'occupational_risks_assessment.applications.applications_table.total_cost',
	},
	{
		id: 'status',
		numeric: true,
		label: 'occupational_risks_assessment.applications.applications_table.status',
	},
	{
		id: null,
		numeric: false,
		label: '',
	},
]
