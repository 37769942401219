export const SET_OCCUPATIONAL_RISK_LOADING = 'occupationalRisks/SET_OCCUPATIONAL_RISK_LOADING'
export const SET_OCCUPATIONAL_RISKS = 'occupationalRisks/SET_OCCUPATIONAL_RISKS'
export const SET_OCCUPATIONAL_RISK_SUCCESS = 'occupationalRisks/SET_OCCUPATIONAL_RISK_SUCCESS'
export const SET_CREATE_OCCUPATIONAL_RISK_XML = 'occupationalRisks/SET_CREATE_OCCUPATIONAL_RISK_XML'
export const SET_INITIAL_OCCUPATIONAL_RISK = 'occupationalRisks/SET_INITIAL_OCCUPATIONAL_RISK'
export const SET_OCCUPATIONAL_RISK_FIELDS = 'occupationalRisks/SET_OCCUPATIONAL_RISK_FIELDS'
export const SET_SELECTED_OCCUPATIONAL_RISKS_STATE = 'occupationalRisks/SET_SELECTED_OCCUPATIONAL_RISKS_STATE'
export const DELETE_OCCUPATIONAL_RISK_SOME = 'occupationalRisks/DELETE_OCCUPATIONAL_RISK_SOME'
export const RESET_OCCUPATIONAL_RISK = 'occupationalRisks/RESET_OCCUPATIONAL_RISK'
export const SET_OCCUPATIONAL_RISK_ERROR = 'occupationalRisks/SET_OCCUPATIONAL_RISK_ERROR'
export const TOGGLE_LOADING = 'occupationalRisks/TOGGLE_LOADING'
export const TOGGLE_SUCCESS = 'occupationalRisks/TOGGLE_SUCCESS'
export const TOGGLE_ERROR = 'occupationalRisks/TOGGLE_ERROR'
export const SET_TERMINATE_XML = 'occupationalRisks/SET_TERMINATE_XML'
export const SET_REMOVE_XML = 'occupationalRisks/SET_REMOVE_XML'
export const SET_RECOVER_XML = 'occupationalRisks/SET_RECOVER_XML'
export const SET_EDIT_XML = 'occupationalRisks/SET_EDIT_XML'
export const GET_DATA_BY_BIN = 'occupationalRisks/GET_DATA_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN = 'occupationalRisks/GET_EMPLOYER_PERMISSION_BY_BIN'
export const GET_EMPLOYER_PERMISSION_BY_BIN_START = 'occupationalRisks/GET_EMPLOYER_PERMISSION_BY_BIN_START'
export const GET_DATA_BY_BIN_START = 'occupationalRisks/GET_DATA_BY_BIN_START'
export const GET_XML_TO_REORGANIZE_LOADING = 'occupationalRisks/GET_XML_TO_REORGANIZE_LOADING'
export const CLEAR_XML_TO_REORGANIZE = 'occupationalRisks/CLEAR_XML_TO_REORGANIZE'
export const SEND_SIGNED_XML_REORGANIZE = 'occupationalRisks/SEND_SIGNED_XML_REORGANIZE'
export const SET_REORGANIZE_SUCCESS = 'occupationalRisks/SET_REORGANIZE_SUCCESS'
export const REORGANIZE_ERROR = 'occupationalRisks/REORGANIZE_ERROR'
export const GET_DATA_BY_BIN_ERROR = 'occupationalRisks/GET_DATA_BY_BIN_ERROR'
export const GET_BRANCHES = 'occupationalRisks/GET_BRANCHES'
export const GET_BRANCHES_LOADING = 'occupationalRisks/GET_BRANCHES_LOADING'
export const GET_STAFF = 'occupationalRisks/GET_STAFF'
export const GET_HARMFUL_LOADING = 'occupationalRisks/GET_HARMFUL_LOADING'
export const GET_HARMFUL_SUCCESS = 'occupationalRisks/GET_HARMFUL_SUCCESS'
export const GET_STAFF_LOADING = 'occupationalRisks/GET_STAFF_LOADING'
export const CLEAR_DATA_BY_BIN = 'occupationalRisks/CLEAR_DATA_BY_BIN'
export const GET_EMPLOYERS_LIST_TO_CHANGE = 'occupationalRisks/GET_EMPLOYERS_LIST_TO_CHANGE'
export const GET_REORGANIZE_XML = 'occupationalRisks/GET_REORGANIZE_XML'
export const GET_FL_DATA = 'occupationalRisks/GET_FL_DATA'
export const GET_XML_FOR_UPDATE_IIN = 'occupationalRisks/GET_XML_FOR_UPDATE_IIN'
export const SEND_XML_FOR_UPDATE_IIN = 'occupationalRisks/SEND_XML_FOR_UPDATE_IIN'
export const RESET_UPDATE_IIN = 'occupationalRisks/RESET_UPDATE_IIN'
export const SET_ERROR_UPDATE_IIN = 'occupationalRisks/SET_ERROR_UPDATE_IIN'
export const SET_EDIT_SUCCESS = 'occupationalRisk/SET_EDIT_SUCCESS'
export const RESET_EDITING = 'occupationalRisk/RESET_EDITING'
export const SET_PARAMS = 'occupationalRisks/SET_PARAMS'

// PACKAGE LOADING
export const GET_XML_TO_SIGN_SOME_LOADING = 'occupationalRisks/GET_XML_TO_SIGN_SOME_LOADING'
export const GET_XML_TO_SIGN_SOME = 'occupationalRisks/GET_XML_TO_SIGN_SOME'
export const GET_XML_TO_SIGN_SOME_ERROR = 'occupationalRisks/GET_XML_TO_SIGN_SOME_ERROR'
export const SEND_SIGNED_XML_PACKAGE_SOME = 'occupationalRisks/SEND_SIGNED_XML_PACKAGE_SOME'
export const DELETE_PACKAGE_SOME = 'occupationalRisks/DELETE_PACKAGE_SOME'
export const RESET_PACKAGE = 'occupationalRisks/RESET_PACKAGE'
export const SET_IS_EDIT = 'occupationalRisks/SET_IS_EDIT'
