import { connect, Field as FormikField } from 'formik'
import { Button, Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

export const ObjectAssessment = connect(
	({ formik: { setFieldValue, values }, name, index, showIndex, isFormDirty, genders, currentLanguage }) => {
		const { t } = useTranslation()
		const stepText = 'occupational_risks_assessment.steps.step_1.objectAssessment.'

		let preffix = name + '[' + index + '].'

		const removeCurrentItem = () => {
			let items = values[name]
			if (index > -1 && index < items.length) {
				const newItems = [...items]
				newItems.splice(index, 1)
				setFieldValue(name, newItems)
			}
		}

		return (
			<section>
				<h5>{t('occupational_risks_assessment.object') + (showIndex ? ' ' + (index + 1) : ' 1')} </h5>
				<Grid container spacing={4} columns={16} marginBottom={3}>
					<Grid item xs={8}>
						<FormikField name={preffix + 'object'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_1')}
									placeholder={t(stepText + 'field_1')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={8}>
						<FormikField name={preffix + 'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_2')}
									placeholder={t(stepText + 'field_2')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
				{showIndex && (
					<Button buttonClassName={'float-end'} type="bare" onClick={removeCurrentItem}>
						{t('remove')}
					</Button>
				)}
			</section>
		)
	}
)
