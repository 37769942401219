import { connect, Field as FormikField } from 'formik'
import { Field } from '@/ui'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import React from 'react'

export const MorbidityAssessment = connect(
	({ formik: { setFieldValue, values }, isFormDirty, genders, currentLanguage }) => {
		const { t } = useTranslation()
		const stepText = 'occupational_risks_assessment.steps.step_6.'

		return (
			<section>
				<Grid container alignItems="flex-end" marginTop={1} marginBottom={2} spacing={4} columns={16}>
					<Grid item xs={4}>
						<FormikField name={'object'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_1')}
									placeholder={t(stepText + 'field_1')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_2')}
									placeholder={t(stepText + 'field_2')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_3')}
									placeholder={t(stepText + 'field_3')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
					<Grid item xs={4}>
						<FormikField name={'fact_address'}>
							{({ field, meta: { touched, error } }) => (
								<Field
									options={genders}
									isRequired
									label={t(stepText + 'field_4')}
									placeholder={t(stepText + 'field_4')}
									{...field}
									error={(touched || isFormDirty) && error}
								/>
							)}
						</FormikField>
					</Grid>
				</Grid>
			</section>
		)
	}
)
