export const tableColumnsEq = [
	{
		id: 'number',
		numeric: false,
		label: 'occupational_risks_assessment.steps.step_2.eq_table.field_1',
	},
	{
		id: 'object',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.eq_table.field_2',
	},
	{
		id: 'date_app',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.eq_table.field_3',
	},
	{
		id: 'planned_dates',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.eq_table.field_4',
	},
	{
		id: null,
		numeric: false,
		label: '',
	},
]

export const tableColumnsProtective = [
	{
		id: 'number',
		numeric: false,
		label: 'occupational_risks_assessment.steps.step_2.protective_table.field_1',
	},
	{
		id: 'object',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.protective_table.field_2',
	},
	{
		id: 'date_app',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.protective_table.field_3',
	},
	{
		id: 'planned_dates',
		numeric: true,
		label: 'occupational_risks_assessment.steps.step_2.protective_table.field_4',
	},
	{
		id: null,
		numeric: false,
		label: '',
	},
]
