import { ProfileTemplate } from '@/widgets'
import { RedirectUnAuthUser } from '@/processes'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { RegulatoryInformationForm } from '@/entity'
import TabsList from '@/ui/atoms/tabsList'
import Tab from '@/ui/atoms/tab'
import { styled } from '@mui/system'
import TabPanelUnstyled from '@mui/base/TabPanel'
import TabsUnstyled from '@mui/base/Tabs'
import RegulatoryInformationTab from '@/entity/regulatoryInformation/ui/tabs/RegulatoryInformationTab'

const Tabs = styled(TabsUnstyled)({})

export const RegulatoryInformation = () => {
	const { t } = useTranslation()
	const [activeTab, setActiveTab] = useState(0)
	const matches = useMediaQuery('(max-width:1000px)')

	const TabPanel = styled(TabPanelUnstyled)({
		width: '100%',
		maxWidth: matches ? '100vw' : '80vw',
		margin: '32px 34px 0 0',
		fontFamily: 'Inter, sans-serif',
		fontSize: '0.875rem',
	})

	const handleChangeTab = (e, newValue) => setActiveTab(newValue)
	return (
		<RedirectUnAuthUser>
			<ProfileTemplate title={t('occupational_risks_assessment.regulatory_information.head_title')}>
				<Tabs defaultValue={0} value={activeTab} onChange={handleChangeTab}>
					<TabsList>
						<Tab value={0}>{t('occupational_risks_assessment.regulatory_information.equipment')}</Tab>

						<Tab value={1}>{t('occupational_risks_assessment.regulatory_information.protective_equipment')}</Tab>
					</TabsList>
					<TabPanel value={0}>
						<RegulatoryInformationTab equipment />
						{/*<RegulatoryInformationForm />*/}
					</TabPanel>
					<TabPanel value={1}>
						<RegulatoryInformationTab />
					</TabPanel>
				</Tabs>
			</ProfileTemplate>
		</RedirectUnAuthUser>
	)
}
