import * as types from './types'

export const actions = {
	toggleLoading: (payload) => ({
		type: types.TOGGLE_LOADING,
		payload,
	}),
	toggleSuccess: (payload) => ({
		type: types.TOGGLE_SUCCESS,
		payload,
	}),
	toggleError: (payload) => ({
		type: types.TOGGLE_ERROR,
		payload,
	}),
	setEquipment: (payload) => ({
		type: types.SET_EQUIPMENTS,
		payload,
	}),
	setEquipmentLoading: (payload) => ({
		type: types.SET_EQUIPMENT_LOADING,
		payload,
	}),
	setEquipmentSuccess: (payload) => ({
		type: types.SET_EQUIPMENT_SUCCESS,
		payload,
	}),
	setEquipments: (payload) => ({
		type: types.SET_EQUIPMENTS,
		payload,
	}),
	setCreateEquipmentXML: (payload) => ({
		type: types.SET_CREATE_EQUIPMENT_XML,
		payload,
	}),
	setRemoveXML: (payload) => ({
		type: types.SET_REMOVE_XML,
		payload,
	}),
	setRecoverXML: (payload) => ({
		type: types.SET_RECOVER_XML,
		payload,
	}),
	setEditXML: (payload) => ({
		type: types.SET_EDIT_XML,
		payload,
	}),
	setEditEquipment: (payload) => ({
		type: types.SET_EDIT_EQUIPMENT,
		payload,
	}),
	getDataByBinStart: (payload) => ({
		type: types.GET_DATA_BY_BIN_START,
		payload,
	}),
	getDataByBin: (payload) => ({
		type: types.GET_DATA_BY_BIN,
		payload,
	}),
	getDataByBinError: (payload) => ({
		type: types.GET_DATA_BY_BIN_ERROR,
		payload,
	}),
	clearDataByBin: (payload) => ({
		type: types.CLEAR_DATA_BY_BIN,
		payload,
	}),
	setInitialEquipment: () => ({
		type: types.SET_INITIAL_EQUIPMENT,
	}),
	setEquipmentFields: (payload) => ({
		type: types.SET_EQUIPMENT_FIELDS,
		payload,
	}),
	setEditSuccess: (payload) => ({
		type: types.SET_EDIT_SUCCESS,
		payload,
	}),
	resetEditing: () => ({
		type: types.RESET_EDITING,
	}),
	setParams: (payload) => ({
		type: types.SET_PARAMS,
		payload,
	}),
	setSelectedEquipments: (payload) => ({
		type: types.SET_SELECTED_EQUIPMENTS_STATE,
		payload,
	}),
	// PACKAGE LOADING
	getXmlToSignSomeLoading: () => ({ type: types.GET_XML_TO_SIGN_SOME_LOADING }),
	getXmlToSignSome: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME,
		payload,
	}),
	getXmlToSignSomeError: (payload) => ({
		type: types.GET_XML_TO_SIGN_SOME_ERROR,
		payload,
	}),
	sendSignedXmlPackageSome: (payload) => ({
		type: types.SEND_SIGNED_XML_PACKAGE_SOME,
		payload,
	}),
	deleteEquipments: (payload) => ({
		type: types.DELETE_EQUIPMENT_SOME,
		payload,
	}),
	resetPackage: (payload) => ({
		type: types.RESET_EQUIPMENT,
		payload,
	}),

	setEquipmentError: (payload) => ({
		type: types.SET_EQUIPMENT_ERROR,
		payload,
	}),
	resetEquipment: () => ({
		type: types.RESET_EQUIPMENT,
	}),
	setIsEdit: (payload) => ({
		type: types.SET_IS_EDIT,
		payload,
	}),
}
